.circular-progress-bar {
  position: relative;
  width: 88px;
  height: 88px;
}

.progress-ring {
  transform: rotate(-90deg);
}

.progress-ring-circle,
.progress-ring-indicator {
  fill: none;
}

.progress-ring-circle {
  stroke:rgb(236, 230, 198);
}

.progress-ring-indicator {
  strokeLinecap: round;
  stroke: #2B2B2B;
}

.progress-ring-text {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
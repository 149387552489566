

.diet-filter .dropdown-menu {
  min-width: 210px;
}

.sort-filter .dropdown-menu {
  min-width: 225px;
}

.macro-filter .dropdown-menu {
  min-width: 350px;
}

@media (min-width: 811px) {
  /* Filter Buttons */
  .diet-filter .dropdown-toggle, 
  .dietary-restrictions-filter .dropdown-toggle, 
  .macro-filter .dropdown-toggle, 
  .product-search, 
  .product-type-filter .dropdown-toggle, 
  .protein-filter .dropdown-toggle, 
  .sort-filter .dropdown-toggle {
    width: 150px;
  }
}

.diet-filter .dropdown-toggle, 
.dietary-restrictions-filter .dropdown-toggle, 
.macro-filter .dropdown-toggle, 
.product-search, 
.product-type-filter .dropdown-toggle, 
.protein-filter .dropdown-toggle, 
.sort-filter .dropdown-toggle {
  border-radius: 0px;
  font-family: "Glegoo", Sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.reset-filters-btn {
  font-family: "Glegoo", Sans-serif !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  background-color: darkgray !important;
  &:hover{
    background-color: #00b156 !important;
    color: white !important;
  }
}

.popular-meal-success-btn {
  font-family: "Glegoo", Sans-serif !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  background-color: #00b156 !important;
}

.popular-meal-unsuccess-btn {
  font-family: "Glegoo", Sans-serif !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  background-color: black !important;
  &:hover{
    background-color: #00b156 !important;
    color: white !important;
  }
}

.macro-filter.dropdown .macro-label,
.sort-filter.dropdown .form-check label,
.dietary-restrictions-filter.dropdown .form-check label,
.diet-filter.dropdown .form-check label,
.product-type-filter.dropdown .form-check label,
.protein-filter.dropdown .form-check label {
  display: inline-block;
  font-family: 'Glegoo', serif;
  font-size: 15px;
  font-weight: 500;
}
.cart-dropdown .dropdown-menu {
  min-width: 325px;
  overflow-x: hidden;
}

/* .cart-items {
  max-height: 575px;
} */

.cart .delivery-zip .delivering-to-label,
.cart .delivery-date .delivery-date-label,
.cart .order-summary .order-summary-label,
.cart .order-summary .subtotal-label {
  font-weight: 700 !important;
  font-size: 16px;
  font-family: 'Barlow', sans-serif;

}
.fit-workout{
    .fw-banner{
        min-height: 290px;
        // //background-image: url(../../../assets/images/mightyfit/banner.png);
        // background-color: #fff;
        // background-repeat: no-repeat;
        // background-size: cover;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // @media only screen and (max-width: 575px) {
        //     min-height: 270px;
        // }
        position: relative;
        .thumbMightyFitLeft {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 300px;
            @media only screen and (max-width: 991px) {
                width: 20%;
            }
        }
        .thumbMightyFitRight {
            position: absolute;
            right: 0;
            top: 0;
            width: 130px;
            @media only screen and (max-width: 991px) {
                width: 10%;
            }
        }
        
        
    }
    .fw-wrapper{
        padding: 50px;
        height: 100%;
        p{
            max-width: 450px;
            padding-left: 4%;
            padding-right: 4%;
            margin: auto;
        }
        .h1 {
            font-size: 40px;
            line-height: 1.2;
            font-weight: 400;
            /* text-transform: uppercase; */
            font-family: 'League Gothic';
            color: #ff5a00 !important;
            @media only screen and (max-width: 767px) {
                font-size: 48px;
            }
        }
        .thumbMightyFitLogo{
            width: 220px;
        }
    }
}


// card part
.fit-workout{
    .card-part {
        margin-top: 200px;
        @media only screen and (max-width: 1199px) {
            margin-top: 160px;
         }
        @media only screen and (max-width: 767px) {
           margin-top: 200px;
        }
    } 
}
.card-part{
    .container-lg{
        max-width: 1200px;
        margin: auto;
    }
    .card-col{
        margin: 15px 0;
        @media only screen and (max-width: 767px) {
            max-width: 550px;
            margin:15px auto;
        }
    }
    .card-link{
        text-decoration: none;
    }
    .cp{
        &-wrapper{
            background: #FAFAFA;
            border: 0.5px solid rgba(112, 112, 112, 0.3);
            border-radius: 2px;
            position: relative;
            height: 100%;
            overflow: hidden;
            &:hover{
                .bg-hover {
                    opacity: 1;
                    visibility: visible;
                   
                }
            }
            .icon-wrap {
                position: absolute;
                right: 15px;
                top: 15px;
                z-index: 1;
                .icon-wrap svg {
                    width: 24px;
                    height: 24px;
                }
            } 
        }
        &-imagewrap{
            position: relative;
            padding-bottom:18px;
            padding: 50px 0 30px;
            cursor: pointer;
            margin: 0 24px;
            position: relative;
            &::before{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url(http://192.168.1.42:3001/static/media/logo_white.af747364c40d4d4871d2.png);
               
                left: 0;
                top: 0;
                background-repeat: no-repeat;
                background-size: contain;
            }
            .imagewrap{
                position: relative;
                //padding-top: 100%;
                padding-top: 56.25%;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                    object-fit: cover;
                }
            }
            // .icon-wrap {
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: space-between;
            //     position: absolute;
            //     top: 0;
            //     right: -8px;
            //     height: 100%;
            //     padding: 18px 0 24px 0;
            //     .icon-wrap svg {
            //         width: 24px;
            //         height: 24px;
            //     }
            // } 
        }
        &-deswrap{
            position: relative;
            height: 100%;
            .cp-show{
                min-height: 110px;
                padding-bottom:24px;
                margin: 0 24px;
                padding-top: 24px;
                border-top: 0.5px solid rgba(112, 112, 112, 0.5);
                .h5 {
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 1.19;
                    color: #2B2B2B;
                    text-transform: capitalize;
                    margin-bottom: 14px;
                }
                .pera {
                    font-family: 'Glegoo';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1.5;
                    color: #707070;
                    text-transform: capitalize;

                    max-height: 42px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                }
                .iconpart {
                    display: flex;
                    align-items: center;
                    margin-top: 18px;
                    .first-part{
                        margin-right: 5px;
                        display: flex;
                        align-items: center;
                        svg {
                            width: 15px;
                            height: 15px;
                            margin-right: 5px;
                        }
                        span {
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1.2;
                            color: #00B156;
                        }
                        &:nth-child(2){
                            svg {
                                width: 8px;
                                height: 8px;
                            }
                        } 
                    }
                }
                
            }
            .bg-hover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #2B2B2B;
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
                border-radius: 0px 0px 2px 2px;
                opacity: 0;
                visibility: hidden;
                transition: 0.3s all;
            }
            .cp-hover{
                width: 100%;
                overflow: auto;
                padding: 24px;
                max-height: 160px;
                overflow: auto;
                .hoverrow{
                    margin-bottom: 14px;
                    &:last-child{
                        margin-bottom: 0; 
                    }
                }
                .hover-col{
                    p{
                        margin: 0;
                        font-family: 'Barlow';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 1.2;
                        color: #B5B5B5;
                    }
                    &.right{
                        p{
                            color: #fff;
                            font-weight: 500;
                        }
                        
                    }
                }
            }
        }
    }
    .btn-wrap{
        margin-top: 23px;
        text-align: center;
        margin-bottom: 50px;
        .btn-load {
            background: #2B2B2B;
            border-radius: 5px;
            padding: 15px 30px;
            color: #fff;
            border: none;
            transition: 0.3s linear;
            &:hover{
                background: #00b156;
            }
        }
    }
}

// detail page
.detail-page{
    .container{
        max-width: 1200px;
        margin: auto;
    }
    .dw-banner{
       padding: 40px 0;
        @media only screen and (max-width: 1199px) {
            padding:60px 0;
        }
        @media only screen and (max-width: 991px) {
            padding:60px 0 50px 0;
        }
        @media only screen and (max-width: 575px) {
            padding:60px 0 30px 0;
        }
        iframe{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .dw-row {
            // margin: 0 -25px;
            align-items: center;
        }
        .dw-col {
            // padding: 0 25px;
        }   
    }
    .dw-innerwrap{
        position: relative;
        padding-top: 68%;
        video,iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: none;
            object-fit: cover;
        }
    }
    .dw-des{
        padding-left: 25px;
        @media only screen and (max-width: 991px) {
            margin-top: 30px;
            padding-left: 0;
        }
        .imgwrap {
            display: flex;
            align-items: center;
            margin-bottom:22px;
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 50%;
                border: 2px solid #00B156;
                padding: 2px;
            }
            span {
                font-family: 'Glegoo';
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 50px;
                text-transform: capitalize;
                color: #2B2B2B;
                margin-left: 15px;
                @media only screen and (max-width: 991px) {
                    font-size: 22px;
                }
            }
        }
        .h4 {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            text-transform: capitalize;
            color: #2B2B2B;
            @media only screen and (max-width: 991px) {
                font-size: 18px;
            }
        }
        .pera {
            font-family: 'Glegoo';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #707070;
            margin: 0;
        }
        .iconpart{
            display: flex;
            align-items: center;
            margin: 12px 0;
            span {
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #00B156;
                margin-left: 4px;
            }
            .first-part{
                margin-right: 4px;
                svg {
                    width: 18px;
                    height: 18px;
                }
                &:nth-child(2){
                    svg {
                        width: 6px;
                        height: 6px;
                    } 
                }
            }
            
        }
        .event-part {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .ep-part {
                margin-right: 18px;
                display: flex;
                align-items: center;
                transition: 0.3s all;
                &:hover{
                    span {
                        color: #00B156;  
                    }
                    svg {
                        // fill: #00B156;
                    }
                     
                }
                svg {
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    cursor: pointer;
                    transition: 0.3s all;
                }
                span {
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    text-transform: capitalize;
                    color: #707070;
                    padding-left: 4px;
                    transition: 0.3s all;
                    cursor: pointer;
                }
            }
        }
        .hoverrow{
            margin-bottom: 12px;
            .hover-col{
                p {
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    margin: 0;
                }
                &.right{
                    p {
                        font-family: 'Barlow';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #2B2B2B;
                    }
                } 
            }
        }
        .cp-hover {
            margin: 30px 0;
            @media only screen and (max-width: 991px) {
                max-width: 350px;
            }  
        }
        .dw-btn{
            display: inline-flex;
            align-items: center;
            background: #2B2B2B;
            border-radius: 5px;
            padding: 15px 30px;
            transition: 0.3s all;
            cursor: pointer;
            &:hover{
                background: #00B156;
                .circle-arrow {
                    border: 2px solid #fff;
                } 
            }
            a {
                text-decoration: none;
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-transform: capitalize;
                color: #FFFFFF;
                margin-right: 32px;
            }
            .circle-arrow {
                width: 18px;
                height: 18px;
                border: 2px solid #00B156;
                border-radius: 21px;
            }
        } 
        
    }
    .dw-desall{
        .h1 {
            font-family: 'League Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 53px;
            line-height: 64px;
            text-align: center;
            text-transform: uppercase;
            color: #2B2B2B;
            margin: 0;
            @media only screen and (max-width: 991px) {
                font-size: 36px;
            }
        }
        .peraall {
            margin-top: 72px;
            @media only screen and (max-width: 991px) {
                margin-top: 50px;
            }
            @media only screen and (max-width: 767px) {
                margin-top: 10px;
            }
            p {
                font-family: 'Glegoo';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 29px;
                color: #2B2B2B;
                b{
                    color: #1F1F1F;
                    font-weight: 700;
                }
            }
            ul{
                li{
                    font-family: 'Glegoo';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 29px;
                    color: #2B2B2B;
                    &::marker {
                        font-size: 10px;
                    } 
                }
            }
        }
    }
    .releted-work{
        // .card-row {
        //     margin: 0 -25px;
        // }
        .rw-heading {
            margin: 70px 0;
            @media only screen and (max-width: 1199px) {
            //     margin: 70px 0 50px;
            // }
            // @media only screen and (max-width: 991px) {
                margin: 50px 0;
            }
            @media only screen and (max-width: 575px) {
                margin: 20px 0 0 0;
            }
            .h2{
                font-family: 'League Gothic';
                font-style: normal;
                font-weight: 400;
                font-size: 53px;
                line-height: 64px;
                text-align: center;
                text-transform: uppercase;
                color: #2B2B2B;
                margin: 0;
                @media only screen and (max-width: 991px) {
                    font-size: 36px;
                }
            }
            
        }
    }

    // .slider-part{
    //     position: relative;

    //     .swiper {
    //         @media only screen and (max-width: 1199px) {
    //             padding-top: 40px;
    //         }
            
    //     }
    //     .card-part{
    //         .cp-wrapper{

    //             .h5{
    //                 height: 42px;
    //                 overflow: hidden;
    //                 display: -webkit-box;
    //                 -webkit-line-clamp: 2;
    //                 -webkit-box-orient: vertical;
    //                 text-overflow: ellipsis;
                    
    //             }
    //             .pera{
    //                 height: 42px;
    //                 @media only screen and (max-width: 767px) {
    //                     -webkit-line-clamp: 4;
    //                     height: 80px;
    //                     max-height: 80px;
    //                 }
    //             }
    //             .iconpart {
    //                 height: 35px;
    //                 flex-wrap: wrap;
    //                 @media only screen and (max-width: 767px) {
    //                     height: auto;
    //                 }
    //             }
    //         }
    //     }
        
    //     .card-col{
    //         width: 100%;
    //         padding: 0;
    //     }
    //     .arrow-wrap {
    //         @media only screen and (max-width: 1199px) {
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //             position: absolute;
    //             top: 0;
    //             left: 0;
    //             width: 100%;
    //         }
    //     }
    //     .arrow-left,.arrow-right{
    //         position: absolute;
    //         top: 50%;
    //         transform: translateY(-50%);
    //         cursor: pointer;
    //         background: #000;
    //         width: 43px;
    //         height: 43px;
    //         border-radius: 50px;
    //         z-index: 1;
    //         &:before{
    //             content: '';
    //             position: absolute;
    //             width: 15px;
    //             height: 15px;
    //             z-index: 21;
    //             opacity: 1;
    //             border-left: 2px solid #00B156;
    //             border-bottom: 2px solid #00B156;
    //         }
    //         @media only screen and (max-width: 1199px) {
    //             position: relative;
    //             right: unset;
    //             transform: unset;
                
    //         }
    //     }
    //     .arrow-left{
    //         left: -90px;
    //         @media only screen and (max-width: 1370px) {
    //             left: 10px;
    //         }
    //         @media only screen and (max-width: 1199px) {
    //             left: unset;
    //             margin: 0 10px;
    //         }
    //     }
    //     .arrow-right{
    //         right: -90px;
    //         @media only screen and (max-width: 1370px) {
    //             right: 10px;
    //         }
    //         @media only screen and (max-width: 1199px) {
    //             right: unset;
    //             margin: 0 10px;
    //         }
    //     }
    //     .swiper-button-disabled{
    //         opacity: 0;
    //     }

    //     .arrow-left{
    //         &:before{
    //             top: 60%;
    //             left: 40%;
    //             transform: rotate(45deg) translate(-50%,-50%);
                
    //         }
    //     }
    //     .arrow-right{
    //         &:before {
    //             content: '';
    //             position: absolute;
    //             width: 15px;
    //             height: 15px;
    //             z-index: 21;
    //             opacity: 1;
    //             border-left: 2px solid #00B156;
    //             border-bottom: 2px solid #00B156;
    //             top: 21%;
    //             right: 27%;
    //             transform: rotate(220deg) translateY(-50%);
                
    //         }
    //     }


    // }
}
.svg_wrap{
    svg{
        &:not(:last-child){
            margin-right: 7px;
        }
    }
}

.iframdiv{
    position: relative;
    width: 100%;
    padding-top: 58.54%;
}

// .slider-part{
//     .swiper-wrapper{
//         // justify-content: center;
//     }
// }
:root {
  --bs-link-hover-color : #{$green};
}

.dropdown-menu {
  --bs-dropdown-link-active-bg : #{$green};
  --bs-dropdown-link-active-color : #{$white};
}

a {
  transition: color ease 0.3s;
}

// Colors
.bg {
  &-red {
    background-color: $red !important;
  }

  &-green {
    background-color: $green !important;
  }

  &-primary-300 {
    background-color: $primary-300;
  }

  &-yellow {
    background-color: $yellow;
  }

  &-image {
    background-image: url('../../../assets/images/referfriend.png');
    background-size: cover;
    background-position: center;
    border-radius: 5px;
  }

  &-linear-gradient {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0.97%, rgba(0, 0, 0, 0.448) 65.63%, rgba(0, 0, 0, 0) 100%);
  }
}

.border {
  &-red {
    border-color: $red !important;
  }

  &-green {
    border-color: $green !important;
  }
}

.form {
  &-label {
    color: $primary-300;
    font-size: 14px;
    font-family: $font-barlow;
    text-transform: capitalize;
    line-height: 1.2;

    &:after {
      content: " *";
      color: $red;
    }
  }

  &-check {
    display: flex;
    align-items: center;

    &-label {
      padding-left: 5px;
      font-size: 14px;
      font-family: $font-barlow;
      flex: 1;
    }

    &-input {
      margin-top: 0;
    }
  }

  &-control {
    font-family: $font-barlow;
    font-size: 14px;
  }
}

.modal-content {
  border-radius: 0px;
}

.dropdown-menu {
  border-radius: 0px;
}

.nowrap {
  white-space: nowrap;
}

.slick-arrow:before {
  color: black;
}

.toast-container {
  z-index: 1090;
}

div.alert:not(.orderTimer) {
  border-radius: 0px;
}

.m-1pct {
  margin: 1%;
}

.my-25px {
  margin-top: 100px;
  margin-bottom: 100px;
}

.my-10px {
  margin-top: 10px !important;
}

.mt-30ipx {
  margin-top: -30px !important;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.p-15px {
  padding: 15px;
}

.h-20px {
  height: 20px !important;
}

.p-30px {
  padding: 30px;
}

.border-black {
  border-color: black !important;
}

.td-none {
  text-decoration: none;
}

.text-inherit{
  text-transform: inherit !important;
}

.spinner-lg {
  height: 5rem;
  width: 5rem;
}

.spinner-xl {
  height: 10rem;
  width: 10rem;
}

.shadow-box {
  border: 2px solid black;
  box-shadow: 6px 6px black;
}

.fa-bg-white {
  background-image: radial-gradient(at center, white 40%, transparent 40%);
}

.desk51{
  height: 51px !important;
}

.main-banner #call-us a {
  text-decoration: none
}

.elementor-page-loading {
  min-height: 750px;
}

.slider-range {
  max-width: 100%;
}

.dropdown-toggle {
  &:after {
    border-top: 0.4em solid currentColor;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    top: 4px;
    position: relative;
  }
}

.my-account-page{
  margin-top: 30px !important;
  margin-bottom: 50px !important;
}

.my-account-nav {
  .dropdown-item {
    white-space: normal;
  }
}

.multi-range-slider {
  min-height: 30px;
}

.slider {
  &-left-value {
    right: 95%;
    top: -14px;
    left: auto;
  }

  &-right-value {
    left: 95%;
    top: -14px;
    right: auto;
  }
}

.navbar-toggler {
  background-color: transparent !important;
}

.card-image {
  aspect-ratio: 1/1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.add-to-meal-plan {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.zindex-full {
  z-index: 9999;
}

.zindex-20 {
  z-index: 700;
}

.zindex-30 {
  z-index: 800;
}

.zindex-40 {
  z-index: 900;
}

.zindex-45 {
  z-index: 950;
}

.zindex-50 {
  z-index: 1000;
}

.rotate-90 {
  transform: rotate(90deg);
}

.table-borderless>tbody>tr>td,
.table-borderless>tbody>tr>th,
.table-borderless>tfoot>tr>td,
.table-borderless>tfoot>tr>th,
.table-borderless>thead>tr>td,
.table-borderless>thead>tr>th {
  border: none !important;
}

#fc_frame:not(.fc-open) {
  bottom: -10px !important;
  right: -3px !important;
  transform: scale(0.7);
}

.object-fit-cover {
  object-fit: cover;
}

.w-full {
  width: 100%;
}

.delivery-processing {
  background-color: #00b156 !important;
  border-radius: 0.3rem;
  position: relative;
}

.delivery-processing:hover:after {
  content: "Your current scheduled order";
  position: absolute;
  bottom: 100%;
  padding: 0.5rem;
  background-color: #333;
  color: #fff;
  border-radius: 0.25rem;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.green-boxes {
  background-color: #00b156;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
}

.orderTabble {
  tbody {
    td {
      --bs-table-accent-bg: #fff !important;
      vertical-align: middle;
    }
  }
  
  @media (max-width:767px) {
    tfoot {
      td {
        width: 100% !important;
        display: block;
        border-bottom-width: 1px;
      }
    }
    .cart-item-row {
      td {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    thead {
      display: none;
    }

    tbody {
      th,
      td {
        --bs-table-accent-bg: #fff !important;
        display: block;
        width: 100%;
        //border-width: 0;
      }
    }

    tfoot {

      th,
      td {
        width: 20%;
        display: block;
        border-bottom-width: 1px;
      }

      tr {
        position: relative;
      }

      th {
        position: absolute;
        left: 0;
        top: 0;
        border-width: 0;
      }

      .form-check {
        min-height: auto;
      }

      .shipping-method-input {
        margin: 0 !important;
      }
    }
    .mobileHide{
      display: none;
    }

    .mobileTd{
      padding-left: 80px;
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;

      &:before{
        content: attr(data-name);
        position: absolute;
        left: 6px;
        top: 0;
        display: flex;
        align-items: center;
        height: 100%;
      }
    }

    .shipping-method-input{
      .form-check:first-child{
        margin-bottom: 5px;
      }
    }

    .plus-minus-input{
      input{
        width: 30px !important;
        min-height: 30px !important;
        padding: 0;
        height: 30px;
      }
      button{
        height: 30px;
      }
    }
  }
}


.order-page{
  .cart{
    height: 81vh;
    display: flex;
    flex-direction: column;

    &-items{
      flex: 1;
      overflow: auto;
    }
    hr {
      margin: 0.5rem 0;
    }
  }
  
}

.giftCardErrorMessage{
  position: relative;
  left: -20% !important;
  z-index: 0;
  @media (max-width:767px) {
    left: 0% !important;
  }


  .toast-container{
    // position: relative;
    @media (max-width:767px) {
      top: -30px !important;
    }
    &::before{
      content: '';
      position: absolute;
      left: 11px;
      bottom: -16px;
      width: 20px;
      height: 20px;
      background-color: #ee3124;
      -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      transform: rotate(-180deg);
    }
  }
  
}

.bundle-save .elementor-button{ 
  border-radius: 38px 38px 38px 38px;
  padding: 18px 47px 18px 47px;
}

.custom-faq-sec  .elementor-accordion-icon svg g{
  fill: #ffd600 !important;
}
.custom-faq-sec .custom-accordion .elementor-accordion-item {
  margin-bottom: 32px !important;
  border-radius: 8px !important;
  border-top:  1px solid #d5d8dc!important;
}
.custom-faq-sec .elementor-widget-wrap .elementor-widget-container {
  border: none !important;
}

.nav-dropdown{
  .dropdown {
    .medudropdown{
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      font-family: inherit !important;
      border: none !important;
    }
    .menuedu{
      position: absolute !important;
      left: 212px !important;
      top: -5px !important;
    }
    button{
      font-family: "League Gothic", sans-serif !important;
      &:focus {
        background-color: unset!important;
        border: none !important;
      }
      &.btn.show {
          border-color: none!important;
      }
      &.fs-26px{
        @media (max-width:1199px) {
          font-size:20px;
        }
      }
    }
    .dropdown-toggle{
      &:after {
        border-top: 0.2em solid currentColor;
        border-right: 0.2em solid transparent;
        border-left: 0.2em solid transparent;
        top: 0px;
      }
    }
    .dropdown-menu {
      --bs-dropdown-link-active-bg:none!important;
      --bs-dropdown-link-active-color: none!important;
    }
  }
} 

.invalid-feedback{
  display: block !important;
}

.react-datepicker-popper{
  z-index: 1000 !important;
}

.perServing{
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  align-items: center;
}

.cartEmptyLogo{
  //background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 90vh;
  position: sticky;
  top: 154px;
}

@media (min-width: 1440px){
  .wrapfilter{
    width: 100%!important;
  }
  .top-wrapFilter .col-xxl-auto{
    flex: 1 !important;
  }
  .top-wrapFilter {
      flex: 1 !important;
  }
  // .bottom-wrapFilter{
  //     flex: 1;
  // }
}


.mb-cards.row {
  justify-content: center;
}
.mightyBucks-page .mb-top {
  width: 23% !important;
}
.crosssells {
  .row {
      padding: 5px 10px !important;
      margin: 0;
      justify-content: space-between;
      flex-wrap: unset !important;
      gap:5px;
      @media (max-width: 1439px) {
        flex-wrap: wrap !important;
        gap: 5px;
      }
      @media (max-width: 991px) {
        gap: 10px 25px;
        justify-content: space-between;
      }
      @media (max-width:767px) {
        justify-content: center;
      }
      @media (max-width: 575px) {
        padding: 5px!important;
        gap: 10px 10px;
      }
  }
  .font-glegoo.col-6 {
      padding: 0;
      @media (max-width: 1439px) {
          width: 75%;
      }
  }
  .plus-minus-input {
    button {
      border: 1px solid #a9a9a9 !important;
      width: 100%;
    }
  }
  .img-wrap{
      width: unset;
      gap: 5px;
      padding: 0;
      @media (max-width: 1439px) {
        width: 100%;
        text-align: center;
        justify-content: center !important;
        align-items: center;
        display: flex !important;
      }
      img {
        width: 40px;
      }
  }
  .col-2 {
    padding: 0;
    width: 30px;
    flex: unset;
  }
}

.react-datepicker-wrapper{
  width: 100%;
}

.editing-wrap.desktop {
  max-width: unset;
  margin: unset;
  padding: unset;
  // margin-bottom: 20px;
  margin-top: 15px;
  margin-bottom: 5px;
  .inner-wrap {
    padding: 15px;
    align-items: baseline;
    .text-editing {
      font-size: 16px;
    }
  }
}

.main-banner{
  position: sticky !important;
  top: 0;
  z-index: 99;
}
nav.navbar.sticky-top {
  position: sticky;
  top: 51px;
}

.needsclick.kl-teaser-SaQMzs.undefined.kl-private-reset-css-Xuajs1 {
  z-index: 949 !important;
}

:not(.klaviyo-form) > .needsclick.kl-private-reset-css-Xuajs1 {
  z-index: 999 !important;
  position: relative;
}

.new-login {
  background: #1B1C1E;
  @media (max-width:991px) {
    .btn-group-login.row .col {
        flex: unset;
    }
    .btn-group-login {
        flex-wrap: wrap;
        gap: 20px;
    }
  }
  .login-page-wrap{
    margin: 0;
    .login-col{
      padding: 85px 20px;
      @media (max-width:767px) {
        padding: 0px 20px 20px;
      }
    }
    // .left-col-login{
    //   position: relative;
    //   &::after{
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-image: url('../../../assets/images/patten.png');
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     background-position: left;
    //     opacity: 0.2; /* Apply the opacity to the pseudo-element */
    //     pointer-events: none;
    //   }
      
    // }
  }
  .inner-col-login {
    padding: 85px 0;
    @media (max-width:767px) {
      padding: 40px 0;
    }
  }
  .col-right{
    max-width: 580px;
    margin: auto;
    display: flex;
    align-items: center;
    height: 100%;
    .btn-part{
      gap: 18px;
      @media (max-width:1200px) {
        gap: 10px;
        svg {
          max-width: 130px;
        }
      }
    }
    .img-part {
      margin-bottom: 30px;
      position: relative;
      padding-top: 50%;
      @media (max-width:767px) {
        height: unset;
        // max-width: 80%;
        margin: 0px auto 20px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: contain;
      }
    }
    .text-part {
      margin: 0 0 20px;
      text-align: center;
      @media (max-width:1200px) {
        max-width: 80%;
        margin: auto;
      }
      @media (max-width:767px) {
        max-width: unset;
        margin: 0 0 20px;
      }
      h1 {
        font-size: 50px;
        font-family: 'Barlow';
        font-weight: 600;
        @media (max-width:1200px) {
          font-size: 30px;
        }
        @media (max-width:767px) {
          font-size: 24px;
        }
      }
      h3 {
        font-size: 20px;
        font-family: 'Barlow';
        @media (max-width:1200px) {
          font-size: 17px;
        }
        @media (max-width:767px) {
          font-size: 15px;
        }
        
      }
    }
  }
  .col-left {
    max-width: 500px;
    margin: auto;
    button{
      height: 50px;
    }
    .google-btn{
      &:hover{
        background-color: #00b156 !important;
        color: #fff;
        border-color: #00b156 !important;
      } 
    }
    .btn-group-login{
      .btn-primary{
        background-color: #1877F2;
        &:hover{
          background-color: #00b156 !important;
          color: #fff;
          border-color: #00b156 !important;
        }
      }
    }
    .forgot-link:hover {
      color: #00b156 !important;
    }
    .login-title{
      font-size: 70px;
      line-height: 1.2;
      font-weight: 600;
      font-family: 'Barlow';
      margin-bottom: 58px;
      @media (max-width:767px) {
        font-size: 40px;
      }
      span{
        display: block;
        font-size: 30px;
        line-height: 1.2;
        font-weight: 500;
        @media (max-width:767px) {
          font-size: 20px;
        }
      }
    }
    .tab-content{
      form{
        input:-webkit-autofill {
          background-color: transparent !important;
          background: transparent !important;
        }
        input[data-autocompleted] {
          background-color: transparent !important;
          background: transparent !important;
        }
        input::placeholder {
          color: #fff !important;
        }
        .form-control{
          background: transparent !important;
          margin: 24px 0;
          
        }
        &.login-form{
          button{
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            font-family: 'Barlow';
          }
        }
        input {
          height: 49px;
          color: #fff !important;
        }
        input[type="checkbox"] {
          height: 16px !important;
          width: 16px;
        }
      }
    }
    .nav-tabs{
      .nav-link  {
        background: transparent;
        border: none;
        color: #fff;
        padding: 10px 30px;
        font-size: 20px;
        text-transform: uppercase;
        font-family: 'Barlow';
      }
      .nav-link.active{
        border-bottom: 4px solid #FFD600
      }
    }
  }
  
}

.order-page{
  .product-filters{
    position: sticky;
    top: 154px;
    z-index: 99;
    @media (max-width: 1200px) {
      top: 146px;
    }
    @media (max-width: 992px) {
      top: 130px;
    }
  }
  .cart{
    position: sticky;
    top: 154px;
  }
}
.dropdown{
  :not(.cart-dropdown-menu, .side-nav).dropdown-menu{
    position: absolute;
    left: 0px;
    width: fit-content;
    @media (max-width: 1440px) {
      position: absolute;
      right: -100px !important;
      left: auto;
      width: fit-content;
    }
    @media (max-width: 1372px) {
      position: absolute;
      right: -50px !important;
      left: auto;
      width: fit-content;
    }
    @media (max-width: 1275px) {
      position: absolute;
      right: 0px !important;
      left: auto;
      width: fit-content;
    }
    @media (max-width: 1070px) {
      position: absolute;
      right: 25px !important;
      left: auto;
      width: fit-content;
    }
  }
}

:not(.medudropdown).nav-dropdown .dropdown button.btn.show {
  color: #3e3c3c !important;
}

@media (max-width: 991px) {
  .wrap-btn button span svg {
    width: 38px !important;
    height: 38px !important;
  }
  .btn-cart {
    width: 33px !important;
    height: 33px !important;
  }
}

.btn.login-btn:hover, .btn.login-btn:focus {
  background-color: #00b156 !important;
  color: #fff !important;
}

.product-filters{
  position: relative;
  &:after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 40px;
    height: 60px;
    z-index: 2;
    @media (max-width: 768px) {
      width: 60px;
    }
  }
  &::before{
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    width: 40px;
    height: 60px;
    z-index: 2;
    @media (max-width: 768px) {
      width: 60px;
    }
  }
  .product-filters-navs{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50px;
    left: 0;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 3;
    .arrow-both {
      background: #00b156;
      color: #fff;
      width: 25px;
      height: 25px;
      pointer-events: auto;
      opacity: 1;
        visibility: visible;
      &:before {
        color: #fff;
        border-color: #fff;
        border-width: 3px;
        top: 10px;
        left: 8px;
      }
      &.disabled {
        opacity: 0;
        visibility: hidden;
      }
      &.left-nav,&.left-nav-mob {
        left: 0;
        top: 68%;
        transform: translateY(-50%);
        @media (max-width: 769px) {
          top: 81%;
        }
        @media (max-width: 640px) {
          top: 68%;
        }
        &:before {
          top: 8px;
          left: 9px;
          font-size: 24px;
        }
        
      }
      &.right-nav,&.right-nav-mob {
        right: 0;
        top: 68%;
        transform: translateY(-50%);
        @media (max-width: 769px) {
          top: 81%;
        }
        @media (max-width: 640px) {
          top: 68%;
        }
        &:before {
          left: 7px;
          top: 8px;
          font-size: 24px;
        }
      }
    }
  }
  .product-filters-list{
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 1480px;
      
    .nav-tabs{
      border-bottom: 1px solid #dee2e6;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      display: block;
      padding-left: 27px;
      padding-right: 27px;
      float: left;
      width: 100%;
      height: 50px;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      @media (max-width: 769px) {
        margin: 0 !important;
      }
      &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        border-radius: 5px;
        background-color: #555555;
        display: none;
      }
      &::-webkit-scrollbar{
        height: 5px;
        background-color: #5f635fb3;
        display: none;
      }
      &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        background-color: #493ee7;
        display: none;
      }
      li {
        display: inline-block;
        button {
          border: none;
          &:hover,&:focus{
            background: transparent !important;
            border-bottom:2px solid #00b156;
            border-radius: 0;
          }
          &.active {
            border-radius: 0;
            border-bottom:2px solid #00b156;
          }
        }
      }
    }
    
  }
}

.hidden {
	display: none;
}
@media (max-width: 1440px) {
  .product-filters-navs {
    display: block;
  }
}
@media (max-width: 768px) {
  .product-btn-part .col-6 {
    padding-left: 5px;
    padding-right: 5px;
}
}
@media (max-width: 640px) {
  .product-filters-navs {
    display: flex;
    justify-content: space-between;
  }
  .product-filters .product-filters-list .product-filters-navs {
      top: 65%;
  }
}
.input-select-part {
  width: 100%;
  display: flex;
  gap: 10px;
  .delivery-zip.me-1 {
    width: 50%;
    margin: 0 !important;
  }
  .delivery-date {
    width: 50%;
    hr {
        display: none;
    }
    .text-danger{
        font-size: 11px;
    }
  }
}
.sort-filter{
  .dropdown-menu{
    z-index: 9 !important;
  }
}
.w-80{
  width: 80% !important;
}
.gpay-button.black{
  background-color: #212529 !important;
}
.my-account-nav{
  .dropdown{
    z-index: 9 !important;
  }
}
.pageloader{
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  width: inherit;
  align-content: center;
  align-items: center;
  max-width: 1320px;
  top: 30%;
  @media (max-width: 1400px) {
    max-width: 1100px;
  }
  @media (max-width: 1200px) {
    max-width: 900px;
  }
  @media (max-width: 992px) {
    max-width: 650px;
  }
  @media (max-width: 768px) {
    max-width: 500px;
  }
}
.blur-bg{
  filter: blur(6px);
  pointer-events: none !important;
}
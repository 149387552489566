.checkout-form .your-order #product-column,
.checkout-form .your-order #subtotal-column {
  width: 70%;
}

.paymentBtn{
  .btn{
    min-width:170px;
    min-height:45px;
    font-size: x-large;
  }
  .gpay-button{
    min-width:170px;
    min-height:50px;
  }
  .gpay-button:hover{
    color: #fff;
    background-color: #00b156 !important;
    border-color: #00b156 !important;
  }
}
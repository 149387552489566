/*Product title */
.product .product-title,
.nyp-product .product-title {
  font-family: 'Glegoo', serif;
  font-size: 20px;
  font-weight: 900 !important;
  margin: 10px 0!important;
  min-height: 48px;
}

.dietary-icons img{
  width: 25px;
  height: 25px;
}

@media (max-width: 767px){
  product .product-title,
  .nyp-product .product-title,
  .product .product-title, .nyp-product .product-title {
    font-size: 16px;
  }
  .dietary-icons img{
    width: 20px;
    height: 20px;
  }

  .card-bootom{
    font-size: 14px;
  }
}

/* Macro Info */
.product .macro-info,
.new-product .macro-info {
  font-family: "Glegoo", Sans-serif;
  font-size: 14px;
}

.product .dietary-icons {
  min-height: 25px;
}

.product .new-badge .badge,
.new-product .new-badge .badge  {
  border-radius: 0px;
  font-size: 1.2em;
  line-height: 1;
  font-family: "Glegoo", Sans-serif;
}

.product .new-badge .badge {
  margin: 0px 0px 0px -8px !important;
}

.product-detail-modal .product-title,
.nyp-product-detail-modal .product-title {
  font-family: 'Glegoo', serif;
  FONT-size: 30px !important;
}

.product-badges .badge {
  border-radius: 0px;
  padding: 5px 10px;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  font-size: 17px;
  @media (min-width: 992px) and (max-width: 1200px) {
    width: 85px !important;
    font-size: 12px;
  }
  @media (max-width: 475px) {
    width: 92px !important;
    font-size: 12px;
  }
  @media (max-width: 420px) {
    width: 80px !important;
    font-size: 10px;
  }
  @media (max-width: 370px) {
    width: 75px !important;
    font-size: 10px;
  }
}

.product-detail-modal .macros-inside-header,
.product-detail-modal .faq-header {
  font-family: "League Gothic", Sans-serif;
  font-size: 41px !important;
  line-height: 50px;
  color: black !important;
}

.product-detail-modal .product-macros .carb-progress-bar .progress-bar {
  background-color: #56c7da;
}

.product-detail-modal .product-macros .fat-progress-bar .progress-bar {
  background-color: #00b156;
}

.product-detail-modal .product-macros .calorie-progress-bar .progress-bar {
  background-color: #000000;
}

.show-product-detail-btn{
  svg {
    font-size: 22px;
  }
}

.giftCardBox{
  border: 1px solid rgba(112, 112, 112, 0.3) !important;
  position: relative;
  .card-image{
    background-color: #2B2B2B !important;
    position: relative !important;

    &-star{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg{
        width: 100%;
        max-width: 85%;
      }
    }
    
    img{
      background-color: transparent;
      position: relative;
      z-index: 5;
    }
  }
  &Content{
    background-color: #FAFAFA;
  }
  &-icon{
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9;
  }

  &-close{
    position: absolute;
    z-index: 88;
    right: 20px;
    top: 20px;
    background: #00B156;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media  (max-width:640px) {
        right: auto;
        left: 20px;
    }
  }

  &-desc{
    position: relative;
    .toast-container{
      width: 100%;
        top: 100% !important;
        display: flex;
        justify-content: center;
    }
  }
}

.giftCardMobileBox{
  .card-image{    
    img{
      z-index: 0 !important;
    }
  }
  &-icon{
    display: none !important;
  }
}


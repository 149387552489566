.mightyBucks-page{
    padding: 40px 0;
    &.container{
       @media(max-width: 992px){
          max-width: 100% !important;
       }
    }
    .mb{
        &-img-wrap {
            height: 100%;
            &.gift-img-wrap{
                position: relative;
                z-index: 2;
            }
        }
       &-title{
          margin-top: 88px;
          margin-bottom: 48px;
          color: #2B2B2B;
          font-size: 48px;
          font-weight: 400;
          line-height: 1.18;
          text-align: center;
          text-transform: uppercase;
          font-family: League Gothic;
          @media (max-width:767px){
             margin-top: 30px;
             margin-bottom: 30px;
          }
       }
       
       &-offer{
          background-color: #2B2B2B;
       }
       &-offer-text{
          color: #fff;
          font-size: 32px;
          font-weight: 600;
          font-family: Barlow;
          text-align: center;
          padding: 20px 0;
          margin-bottom: 48px;
          @media(max-width: 767px){
             font-size: 22px;
             padding: 10px 0;
          }
          span{
             color: #00B156;
          }
       }
       &-card-wrap{
        display: flex;
        justify-content: center;
        // grid-template-columns: repeat(2, 1fr);
        // column-gap: 30px;
        // row-gap:  30px;
        max-width: 900px;
        margin: auto;
        padding: 0 20px;
        flex-wrap: wrap;
        @media (max-width: 575px) {
           flex-direction: column;
        }
        //   @media (max-width: 992px) {
        //      grid-template-columns: repeat(2, 1fr);
        //      column-gap: 15px;
        //      row-gap:  15px;
        //   }
        //   @media (max-width: 575px) {
        //      grid-template-columns: repeat(1, 1fr);
        //   }
       }
       &-top{
        margin: 10px;
        width: 40%;
        @media (max-width: 575px) {
            // width: 100%;
            // margin: 10px 0;
        }
        &.gift-card-wrap{
            button {
                background-color: #000!important;
                color: #fff!important;
                --bs-btn-border-radius: 0rem!important;
            }
            .mb-price{
                    color: #000!important;
            } 
        }
        .nyp-input{
            .col1{
                max-width: 20px !important;
            }
            .col2 {
                max-width: 110px;
            }
        }
            position: relative;
            padding: 10px 25px 24px 25px;
            background: #FAFAFA;
            border: 1px solid rgba(#707070, 30%);
            border-radius: 2px;
            @media (max-width: 1399px) {
                padding: 10px 11px 24px 11px;
            }
            @media (max-width: 992px) {
                padding: 10px 40px 20px 40px;
            }
            @media (max-width: 767px) {
                    padding: 10px 10px 20px 10px;
            }
            .toast-container{
                z-index: 2 !important;
            }
            .toast-header{
                .btn-close{
                    color: #000 !important;
                    background-color: transparent !important;
                }
            }
            button {
                &:hover{
                    color: #fff !important;
                    background-color: #707070!important;
                }
            }
            &:first-child{
                button {
                    color: #fff;
                    --bs-btn-border-radius: 0rem!important;
                }
                .mb-price{
                   color: #00b156;
                }
            }
            &:nth-child(2){
                button {
                    background-color: #fed700;
                    --bs-btn-border-radius: 0rem!important;
                }
                .mb-price{
                        color: #fed700;
                }
            } 
            &:nth-child(3){
                button {
                    background-color: #ee2c1e;
                    color: #fff;
                    --bs-btn-border-radius: 0rem!important;
                }
                .mb-price{
                        color: #ee2c1e;
                }
            } 
            // &:nth-child(4){
            //     button {
            //         background-color: #000;
            //         color: #fff;
            //         --bs-btn-border-radius: 0rem!important;
            //     }
            //     .mb-price{
            //             color: #000;
            //     }
            // } 
         
       }
       &-line{
          display: block;
          margin: 13px auto 0;
          width: 239px;
          height: 0.5px;
          border:none;
       }
       &-bg-wrap{
          position: relative;
          padding: 40px 0;
       }
       &-bg-image{
          position: absolute;
          top:0;
          left: 0;
          width: 100%;
          height: 100%;
          img{
             width: 100%;
             height: 100%;
             object-fit: contain;
          }
       }
       &-top-wrap{
          position: relative;
          display: flex;
          aspect-ratio: 250/157;
        //   border-radius: 10px;
          overflow: hidden;
          // @media (max-width: 576px){
          //    max-width: 300px;
          //    margin: auto;
          // }
       }
       &-right{
          width: 80%;
          img{
             width: 100%;
             height: 100%;
             object-fit: cover;
          }
       }
       &-left{
          position: relative;
          width: 40%;
          &::before{
             content: '';
             position: absolute;
             top: 0;
             left: 0;
             width: 162%;
             height: 100%;
             background-color: #2B2B2B;
             clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
          }
          &::after{
             content: '';
             position: absolute;
             top: 0;
             left: 0;
             width: 80%;
             height: 100%;
             background: linear-gradient(168deg, rgba(13, 19, 27, 0.60) 0%, rgba(24, 34, 49, 0.00) 100%);
             clip-path: polygon(0 0, 100% 0%, 53% 100%, 0% 100%);
          }
       }
       &-bg-gradient{
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 200%;
          height: 100%;
          background: linear-gradient(to left, rgba(13,19,27,0.6) 0%,rgba(24,34,49,0) 100%);
          clip-path: polygon(0 0, 100% 0%, 78% 100%, 0% 100%);
       }
       &-img-content{
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          padding: 12px;
          z-index: 1;
       }
       &-logo{
          max-width: 100px;
          width: 100px;
       }
       &-subtitle{
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
       }
       &-price{
          font-family: Barlow;
          font-size: 30px;
          font-weight: 600;
          color: #00B156;
       }
       &-img-block{
          position: absolute;
          width: 100%;
          height: 100%;
          top:0;
       }
       &-gift{
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
       }
       &-img-wrap{
          img{
             width: 100%;
             height: 100%;
             object-fit: cover;
          }
       }
       &-content-block{
          padding-top: 24px;
          text-align: center;
          // @media(max-width: 576px){
          //    max-width: 300px;
          //    margin: auto;
          // }
 
          h5{
             font-size: 20px;
             font-weight: 500;
             font-family: Barlow;
             line-height: 1.2;
          }
          p{
             font-size: 14px;
             font-family: Glegoo;
             color: #000;
             line-height: 1.7;
            //  max-width: 190px;
             margin: auto;
             padding-bottom: 18px;
          }
       }
       &-cart-block{
          position: relative;
          margin: 48px 0;
          background-color: #FBFBFB;
          border: 0.5px solid rgba(#707070, 30%);
          border-radius: 2px;
          padding: 32px;
          @media (max-width: 767px){
             padding: 10px;
          }
       }
       &-item{
             
            
          &-wrap{
             position: relative;
             display: flex;
             align-items: center;
             padding-top: 28px;
             padding-bottom: 28px;
             border-bottom: 0.5px solid rgba(#707070, 30%);
             @media(max-width: 576px){
                flex-wrap: wrap;
             }
             &:first-child{
                padding-top: 0;
                @media (max-width: 576px){
                   padding-top: 28px;
                }
             }
             &:last-child{
                border-bottom: none;
             }
          }
         &-left{
          width: 50%;
          @media(max-width: 576px){
             width: 100%;
          }
         }
         &-right{
          width: 50%;
          @media(max-width: 576px){
             width: 100%;
             margin-top: 20px;
          }
         }
       }
       &-order-summury{
          position: relative;
          margin: 48px 0;
          width: 100%;
       }
       &-order-btn{
          text-align: right;
          margin-top: 28px;
       }
       &-order-block{
          display: flex;
          justify-content: flex-end;
       }
       &-order-wrap{
          background-color: #FBFBFB;
          border: 0.5px solid rgba(#707070, 30%);
          width: 369px;
          padding: 32px;
          @media(max-width:576px){
             width: 100%;
          }
          h6{
             font-size: 24px;
             font-weight: 600;
             font-family: Barlow;
             margin-bottom: 28px;
          }
       }
       &-order-twrap{
          border-bottom: 0.5px solid rgba(#707070, 30%);
       }
       &-order-total{
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
       }
       &-order-text{
          font-size: 16px;
          font-weight: 600;
          font-family: Barlow;
          color: #707070;
          line-height: 1.3;
       }
       &-order-price{
          font-size: 16px;
          font-weight: 600;
          font-family: Barlow;
          color: #1B1C1E;
          line-height: 1.3;
       }
       &-order-subtotal{
          display: flex;
          justify-content: space-between;
          padding-top: 25px;
       }
    }
    .mil{
       &-wrap{
          display: flex;
          gap: 28px;
          align-items: center;
       }
       &-img-wrap{
          width: 100%;
          max-width: 143px;
          @media(max-width:767px){
             width: 90px;
          }
       }
       &-title{
          font-size: 18px;
          font-weight: 700;
          font-family: Glegoo;
          margin-bottom: 12px;
       }
       &-specification{
          font-size: 12px;
          font-weight: 400;
          font-family: Glegoo;
          line-height: 1.75;
          margin-top: 5px;
          @media(max-width: 767px){
             text-align: left;
          }
       }
       &-images{
          display: flex;
          column-gap: 8px;
       }
    }
    .mir{
       &-content-wrap{
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media(max-width: 992px){
             justify-content: left;
             gap: 50px;
          }
       }
       &-price{
          font-size: 18px;
          font-weight: 500;
          font-family: Barlow;
       }
    }
    .btn-link{
       @media(max-width: 992px){
          position: absolute;
          right: 20px;
          top: 30px;
       }
       @media(max-width: 576px){
          position: absolute;
          right: 0;
          top:  5px;
       }
    }
    .btn.bg-black{
       color: #00B156;
       font-weight: 600;
       font-size: 16px;
       &:hover{
          color: #fff;
       }
    }
 }

 .introducing-container{
    margin-bottom: 30px;
     .introducing{
        width: 100%;
        text-align: center;
        padding: 0 20px;
        max-width: 680px;
        margin: auto;
        h1 {
            font-size: 4rem !important;
            font-family: "League Gothic", Sans-serif;
            text-transform: uppercase;
            letter-spacing: -0.2px;
        }
        span{
            color: #00b156;
        }
        h2 {
            font-family: "Glegoo", Sans-serif;
        }
        p {
            text-align: center;
        }
     }
 }

 @media (max-width: 1200px){
   .mightyBucks-page .mb-top {
     width: 31% !important;
   }
 }
 @media (max-width:992px){
   .mightyBucks-page .mb-top {
     width: 37% !important;
   }
 }
 @media (max-width:600px){
   .mightyBucks-page .mb-top {
     width: 48% !important;
   }
 }
 @media (max-width:575px){
   .mightyBucks-page .mb-top {
     width: 80% !important;
   }
 }
.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
}

.img-preview {
    overflow: hidden;
}

.profile-modal{
  
  .cropper{
    &-container{
      border-radius: 5px;
      overflow: hidden;
    }
    &-wrap{
      width: 100%;
      position: relative;

      &:before{
        content: '';
        display: block;
        padding-top: 100%;
      }

      &-inner{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
    &-view-box,
    &-face {
        border-radius: 100%;
        overflow: hidden;
        outline: 5px solid #ffffff;
        outline-color: rgba(255, 255, 255, 100%);
    }
  }
  .modal{
    &-content{
      border-radius: 5px;
      overflow: hidden;
    }
    &-header{
      background-color: #2B2B2B;
      border-radius: 0;
    }

    &-footer{
      border-top: 0;
      padding-top: 0;
    }
  }

  .btn-close{
    background-color: #00B156;
    opacity:100;
    font-size: 24px;
    border-radius: 100%;
    padding: 0 !important;
    background-size: 12px;

    &:hover,
    &:focus{
      background-color: #00B156 !important;
      opacity: 0.8;
    }
  }
}

.ddlShowOptions{
    padding: 18px 24px;
    position: absolute;
    width: 244px;
    left: -5px;
    top: 25px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    &Text{
      font-size: 16px;
      color:#707070;
      flex: 1;
      padding-left: 12px;
    }

    &Icon{
      width: 24px;
      display: flex;
    }

    &Row{
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
}

.profilePicMain{
  width: 1em;
  font-size: 110px;
  
  @media (max-width:992px) {
    font-size: 90px;    
  }
}

.profileName{
  flex: 1;
}

.profileupload{
  position: absolute;
  bottom: -5px;
  right: 5px;
  width: 30%;
  height: 30%;
  max-width: 32px;
  max-height: 32px;
  border-radius: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    .ddlShowOptions {
      opacity: 1;
      z-index: 999;
      visibility: visible;
      pointer-events: auto;
    }
  }
}

.profilePic{
  width: 1em;
  height: 1em;
  font-size: inherit;
  border-radius: 100%;
  border: 3px solid #2B2B2B;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.profileAvtar{
  border-radius: 100%;
}

.controls {
  &-rotate{
    cursor: pointer;
  }

  &-zoom{
    display: flex;
    align-items: center;
    justify-content: center;
    
    button {
      background-color: transparent;
      border-radius: 100%;
      width: 1em;
      height: 1em;
      padding: 0;
      font-size: 20px;
      border: 2px solid #2B2B2B;
      position: relative;
      margin-right: 10px;
  
      &:focus, &:hover{
        background-color: transparent;
        outline: none;
      }      
      
      &:before{
        content: "";
        width: 0.5em;
        height: 2px;
        background-color: #2B2B2B;
        border-radius: 4px;
        display: block;
        margin: auto;
      }
  
      &.zoom-out{
        margin-right: 0;
        margin-left: 10px;
        &:after{
          content: "";
          height: 0.5em;
          width: 2px;
          background-color: #2B2B2B;
          border-radius: 4px;
          display: block;
          margin: auto;
          position: absolute;
          top: 0.15em;
          left: 0.35em;
        } 
      }
    }
    
    input[type="range"] {
      flex: 1;
      // height: 0px;
      width: 100%;
      // border: 2px solid #00B156;
  
    }
    input[type=range]{
      -webkit-appearance: none;
    }
    
    input[type=range]::-webkit-slider-runnable-track {
        width: 300px;
        height: 5px;
        background: #00B156;
        border: none;
        border-radius: 3px;
    }
    
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #00B156;
        margin-top: -6px;
    }
    
    input[type=range]:focus {
        outline: none;
    }
    
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #00B156;
    }
    
    input[type=range]{
      /* fix for FF unable to apply focus style bug  */
      border: 1px solid white; 
    }
    
    input[type=range]::-moz-range-track {
      width: 100%;
        height: 5px;
        background: #00B156;
        border: none;
        border-radius: 3px;
    }
    
    input[type=range]::-moz-range-thumb {
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #00B156;
    }
    
    /*hide the outline behind the border*/
    input[type=range]:-moz-focusring{
        outline: 1px solid white;
        outline-offset: -1px;
    }
    
    input[type=range]:focus::-moz-range-track {
        background: #00B156;
    }
  }
}
  
.mm-logo {
  max-width: 250px;
  @include media-breakpoint-down(lg){
    max-width: 145px;
  }
}
.mm-affiliate {
  max-width: 250px;
  @include media-breakpoint-down(lg){
    max-width: 60px;
    object-fit: contain;
    padding: 0;
  }
}
.mm-thumbnail {
  @include media-breakpoint-down(lg){
    max-width: 80px;
    object-fit: contain;
    padding: 0;
  }
}

.socialfeed{
  a{
    color: #fff;
    display: inline-flex;
    align-items: center;
    &:hover{
      path{
        color: $green;
      }
    }

    &.sb-apple{
      &:before{
        content: '';
        display: block;
        height: 18px;
        border-left: 1px solid $green;
      }
    }
  }
}
.navbar{
  background-color: $white;
  .btn:hover{
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }
  @include media-breakpoint-down(lg){
     width: 100%;
    
    &-nav{
      a:not(.btn){
        color: $white;
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
      }

      .account-menu{
        .nav-link{
          border-width: 0;
        }
        .btn{
          margin-top: 10px;
        }
      }
    }
    
    &-wrap{
      background-color: $primary-200;
      position: fixed;
      top: 10px;
      left: 0;
      width: 100%;
      border-radius: 1rem;
      padding-top: 18px;
      transition: ease 0.3s;
      transform: translate(100%);
      height: auto;
      overflow: scroll;

      &.isOpen{
        transform: translate(0);
      }
    }

    .menu{
      &-top,
      &-bottom{
        width: 100%;
      }
    }

    .menu{
      &-bottom{
        @include media-breakpoint-down(lg){
          flex: 1;
          max-height: 85vh;
          padding-bottom: 30px;

          ul{
            ul{
              a{
                font-size: 12px;
                font-family: $font-barlow;
                border-width: 0;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xl){
    &-nav{
      a:not(.btn){
        &.fs-26px{
          @include media-breakpoint-up(lg){
            font-size:20px;
          }
        }
      }
    }
  }
}

.btn-cart{
  width: 36px;
  height: 36px;
  padding: 0;
}

.cart-badge{
  width: 24px;
  height: 24px;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -20px;
  font-weight: 400;
  --bs-badge-font-size : 12px;
}

.aboutNav-link{
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.main-banner {
  .mm-affiliate {
    background-color: transparent;
    max-width: 250px;
    @include media-breakpoint-down(lg){
      max-width: 35px !important;
      object-fit: contain;
      padding: 0;
    }
  }
}
.goBackIcon{
  height: 30px;
}
footer.footer {
  padding: 50px 0 20px 0;
}

@media (max-width:768px) {
  footer.footer {
  padding: 30px 0 20px 0;
}
}

footer.footer .footer-logo {
  padding: 10px;
  aspect-ratio: auto 400 / 424;
  height: 168.531px;
  width: 159px;
}

footer.footer a {
  text-decoration-line: none;
}
footer.footer h1.heading {
  font-family: 'League Gothic', sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin: 0 0 16px 0;
}

@media (max-width:768px) {
  footer.footer h1.heading {
    margin-bottom: 8px;
  }
}
footer.footer .footer-column {
  padding: 10px;
}

footer.footer #email::placeholder {
  font-family: Barlow sans-serif;
  font-size: 15px;
}

footer.footer .subscribe-btn {
  font-family: Glegoo, sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.f-social{
  min-width: 24px;
}

footer{
  a:hover{
    text-decoration: underline;
  }
}

.footer-max-width{
  max-width: 1800px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
}
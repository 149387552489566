@media (max-width: 480px) {
  #scroll-to-top-btn {
    display: none;
    position: fixed;
    bottom: 20%;
    z-index: 99;
  }
}
@media (min-width: 481px) {
  #scroll-to-top-btn {
    display: none;
    position: fixed;
    bottom: 20px;
    z-index: 99;
  }
}

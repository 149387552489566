
.my-account-page{
  color: $primary-300;
}
.edit-account-page{
  .btn {
   --bs-btn-padding-x: 0.8rem;
   --bs-btn-padding-y: 0.6rem;
  }
 .form-control{
    border: 1px solid #ced4da;

    &:focus{
      border-color: $green;
    }
  }
  .accordion{
    .btn{
      --bs-btn-padding-x: 1.7rem;
      --bs-btn-padding-y: 0.7rem;
      --bs-btn-font-weight : 500;
      --bs-btn-font-family: #{$font-barlow};
    }
    --bs-accordion-bg : #FAFAFA;      
    &-button{
      @include media-breakpoint-up(lg){
        font-size: 24px;
      }
      font-family: $font-barlow;
      font-weight: 500;
    }
  }
}

.my-account-nav{
  .btn{
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    @include media-breakpoint-up(lg){
      font-size: 18px;
    }
    --bs-accordion-btn-icon-width : 1.25rem;
    --bs-accordion-btn-active-icon : url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-icon : url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    
    &:after{
      border-width: 0 !important;
      background-size: var(--bs-accordion-btn-icon-width);
      background-image: var(--bs-accordion-btn-icon);
      width: var(--bs-accordion-btn-icon-width);
      height: var(--bs-accordion-btn-icon-width);
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover, &.show, &.bg-dark{
      &::after{
        background-image:var(--bs-accordion-btn-active-icon);
      }
    }
    &.show{
      &:after{
        transform: rotate(180deg);
      }
    }

    @include media-breakpoint-up(lg){
      font-size: 22px;
    }
  }
  .dropdown{
    &-menu{
      padding-top: 0;
      padding-bottom: 0;
      position: relative !important;
      transform: none !important;
      background-color: $primary-300;
      --bs-dropdown-border-width : 0;
      --bs-dropdown-link-color : #{$white};
      --bs-dropdown-zindex : 900;
    } 

    &-item{
      font-size: 15px;
      @include media-breakpoint-up(lg){
        font-size: 17px;
      }
      border-bottom: 1px solid rgba($color: $white, $alpha: 0.30);
      padding-top: 12px;
      padding-bottom: 12px;
      --bs-dropdown-link-active-bg: --bs-dropdown-link-hover-bg
    }
  } 

  .progress{
    --bs-progress-bg : #{$primary};
  }
}
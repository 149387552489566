.order-received-page .thank-you-alert {
  background: #00b156;
}

.order-received-page {
  margin-top: 5rem;
  @media (max-width:767px) {
    margin-top: 3rem;
  }
  .orderTimer{  
    --bs-alert-bg : #00B156;
    // --bs-alert-border-radius : 2.25rem;
    padding: 20px 40px;
    width: 100%;
    //max-width: 65.5625rem;
    margin-left: auto;
    margin-right: auto;

    @media (max-width:1024px) {
      padding: 20px;
    }

    @media (max-width:767px) {
      padding:1rem;
    }
  }   

  .my-5.product-add.container {
      display: flex;
      @media (max-width: 1199px) {
         flex-direction: column;
         padding: 0;
      }
      .adddetail{
        max-width: 25%;
        display: inline-block;
        @media (max-width: 1199px) {
          max-width: 100%;
          display:block;
          margin-bottom: 20px;
        }
        .head{
          letter-spacing: -1.5px;
          font-weight: 700;
          line-height: 1.5;
          font-size: 22px;
          padding-right: 15px;
        }
        p{
          font-weight: 400;
          font-size: 2.5rem;
          line-height: 1.3;
          font-family: 'Barlow', sans-serif;
          text-transform: uppercase;
          max-width: 290px;
          @media (max-width: 1199px) {
            max-width: 100%;
          }
          @media (max-width:1024px) {
            font-size: 2rem;
            line-height: 1.2;
          }
        }
      }
      .mightyBucks-page{
          width: 75%;
          @media (max-width: 1199px) {
           width: 100%;
          padding: 0;
          }
      }
  }
  .PlaceNextOrder{
    .first-col{
      text-align: left;
      h4{
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: -1.5px;
        color: #1F1F1F;
        font-size: 22px;
      }
    }
  }
  
  
  .PlaceNextOrderText{
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 1.3;
    font-family: 'Barlow', sans-serif;
    // @media (max-width:1199px) {
    //   font-size: 2.5rem;
    // }
    @media (max-width:1024px) {
      font-size: 2rem;
      line-height: 1.2;
    }
    @media (max-width:767px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    
  }

  .ot{
    &-grid{
      display: grid;
      grid-template-columns: repeat(3, (1fr));
      grid-gap: 27px;
      margin-left: auto;
      margin-right: auto;
      max-width: 40.3125rem;
      width: 100%;
      margin-top: 1.75rem;
      margin-bottom: 3rem;
      @media (max-width:767px) {
        grid-gap: 10px;
        margin-bottom: 2rem;
      }

      &-box{
        background: #1F1F1F;
        border-radius: 22px;
        height: 9.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width:1024px) {
          height: 6.5rem;
        }
        @media (max-width:767px) {
          height: auto;
          padding: 1rem;
        }
      }

      &-time{
        font-weight: 600;
        font-size: 4rem;
        line-height: 4.8125rem;
        font-family: 'Barlow', sans-serif;
        display: block;
        color: #ffffff;
        text-align: center;
        @media (max-width:1024px) {
          font-size: 2rem;
          line-height: 2.8125rem;
        }
        @media (max-width:767px) {
          font-size: 2.5rem;
          line-height: 1.2;
        }
      }

      &-label{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #00B156;
        @media (max-width:1024px) {
          font-size: 13px;
        }
        @media (max-width:767px) {
          font-size: .75rem;
        }
      }
    }
  }

  .btn{
    text-transform: uppercase;
    &:hover{
      background-color: #ffffff;
      color: #000000;
    }
  }
}

.autoShip{
  max-height: 250px;
  .autoshipHeader{
    color: #666666;
    font-size: 3.5rem;
    line-height: 1.3;
    font-family: 'League Gothic';
    text-transform: uppercase;
  }
  .autoshipText{
    color: #666666;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.3;
    font-family: 'Barlow';
  }
  .loader{
    display: flex;
    justify-content: center;
    top: -80px;
    position: relative;
  }
  
  @media (max-width:1024px) {
    font-size: 2rem;
    line-height: 1.2;
  }
  @media (max-width:767px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
}
.detail-wrapper{
  // overflow-x: hidden;
  @media (min-width:1450px) {
    margin: 0 -75px;  
  }
  @media only screen and (max-width: 1450px) and (min-width: 1280px){
    margin: 0;  
  }
  // padding-top: 25px;
}

.video-content{
  width: 100%;
  max-width: 100%;
}

.arrow {
  &-wrap{
    @media (max-width:640px) {
      margin-top: 25px;
      display: flex;
      justify-content: center;
    }
  }
  &-both{
    position: relative;
    @media (min-width:640px) {
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
    }
    cursor: pointer;
    background: #000;
    font-size: 43px;
    width: 1em;
    height: 1em;
    border-radius: 50px;
    z-index: 1;
    &:before{
        content: '';
        position: absolute;
        width: .35em;
        height: .35em;
        z-index: 21;
        opacity: 1;
        border-left: 2px solid #00B156;
        border-bottom: 2px solid #00B156;
      }
    }
    &-left{
      left: 0px;        
      // @media (min-width:812px) {
      //   left: -10px;        
      // }
      @media (min-width:1450px) {
        left: 20px;
      }
      @media only screen and (max-width: 1450px) and (min-width: 1280px){
        left: -45px; 
      }
      &:before{
        left: 1rem;
        top: .875rem;
        transform: rotate(45deg);       
      }
    }
    &-right{
      right: 0px;
      // @media (min-width:812px) {
      //   right: -10px;        
      // }
      @media (min-width:1450px) {
        right: 20px;
      }
      @media only screen and (max-width: 1450px) and (min-width: 1280px){
        right: -45px; 
      }
      
      &:before {
        top: .875rem;
        left: .625rem;
        transform: rotate(225deg);
      }
    }
  }
  .swiper-button-disabled{
    opacity: 0;
    display: none;
  }

  .product .product-title, .nyp-product .product-title{
    font-size: 18px;
  }


  .upsellsCarousel{
    @media (min-width:640px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media (min-width:1450px) {
      padding-left: 70px;
      padding-right: 70px;
    }
    @media only screen and (max-width: 1450px) and (min-width: 1280px){
      padding: 0; 
    }
    padding-top: 16px;

    .swiper-wrapper{
      padding-bottom: 5px;
    }

    .card-part{
      padding-left: 5px;
      padding-right: 5px;
    }
    

    .swiper-slide{
      height: auto;
    }

    .card-part,
    .card-col{
      height: 100%;
      margin: 0 !important;
    }
  }

  @media only screen and (max-width: 1450px) and (min-width: 1280px){
    .product-detail-modal .arrow-right {
        right: 0 !important;
    }
    .product-detail-modal .arrow-left {
        left: 0 !important;
    }
  }
  .product-detail-modal .arrow-both {
      background: #32c077;
  }
  .product-detail-modal .arrow-both:before {
      border-left: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
  }
.elementor{
  &-button{
    --e-global-typography-secondary-line-height : 1.6;
    padding: 0.8rem 1.8rem;
    font-family: $font-barlow;
    border-radius: 4px;
    //line-height: var(--e-global-typography-secondary-line-height);
    
    .btn-outline &{
      padding: 0.6rem 1.8rem;
    }

    &:hover, &:focus{
      background-color: $green !important;
      color: $white !important;
      border-color: $green !important;

      .btn-dark-hover &{
        background-color: $black !important;
      }
    }
  }
}


.btn{
  font-family: $font-barlow !important;
  --bs-btn-font-family : $font-barlow;

  &-link{
    padding: 0;
    &:focus, &:hover{
      background-color: transparent;
      color: $green;
    }
  }
  &-close{
    &:focus, &:hover{
      background-color: transparent !important;
      color: $green;
    }
  }

  &-dark{
    --bs-btn-hover-bg  : #{$green};
    --bs-btn-hover-border-color  : #{$green};
  }

  &-sm{
    font-weight: 500;
    --bs-btn-padding-y: 0.625rem;
    --bs-btn-padding-x: 1.25rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-border-radius: 0.25rem;
  }
  &-lg{
    --bs-btn-font-size: 1.125rem;
    --bs-btn-border-radius: 0.25rem;
    --bs-btn-padding-y: 0.73rem;
  }

  &-success{
    --bs-btn-color: #{$primary};
  }

  &.bg{
    &-black{
      border-color: #000;
      &:hover,
      &:focus{
        background-color: $green !important;
        color: $white;
        border-color: $green !important;
      }
    }
    &-white{
      &:hover, &:focus{
        background-color: #000 !important;
        color: #fff !important;
      }
    }
  }
}

[type="button"]:focus,
[type="submit"]:focus{
  background-color: $green;
}

.needsclick  {
  .klaviyo-close-form{
    border-radius: 100%;
    &:hover, &:focus{
      background-color: transparent;
      circle{
        fill: $green;
      }
      path{
        stroke: $white;
      }
    }
  }
}

.klaviyo{
  &-form {
    button.needsclick{
      border-radius: 0 !important;
      font-size: 15px !important;
      font-weight: 600 !important;
      transition: all !important;
      border-width: 0 !important;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
      text-transform: uppercase;
      font-family: $font-barlow !important;

      &:hover{
        background-color: $green !important;
        color: #fff !important; 
      }
    }
  }
}

$primary : #1B1C1E;
$primary-100 : #1B1C1E;
$primary-200 : #1F1F1F;
$primary-300 : #2B2B2B;
$green : #00b156;
$red : #ee3124;
$yellow : #FFD600;

$font-barlow : 'Barlow', sans-serif;
$font-league-gothic : 'League Gothic', sans-serif;
$font-glegoo : Glegoo, sans-serif;

$form-select-padding-y: 0.5rem;
$form-select-padding-x: 1rem;
$input-padding-y :0.47rem;

$input-focus-border-color : $green;
$input-focus-box-shadow : transparent;
.filter-part {
    padding: 50px;
    background: #FAFAFA;
    border-bottom: 0.5px solid #707070;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 98;
    width: 100%;

    @media only screen and (max-width: 1199px) {
       padding: 30px 0;
    }

    .container {
        max-width: 1200px;
        margin: auto;
    }

    .fp {
        &-wrapper {
            .first-filter {
                padding-top: 40px;
                display: flex;

                @media only screen and (max-width: 991px) {
                    flex-direction: column;
                }
            }

            .filter-row {
                width: 100%;
                flex-wrap: nowrap;

                @media only screen and (max-width: 1199px) {
                    flex-wrap: nowrap;
                }

                @media only screen and (max-width: 767px) {
                    flex-wrap: wrap;
                }

                &.right-filter {
                    .filter-col {
                        @media only screen and (max-width: 575px) {
                            width: 100%;
                        }

                        &:nth-child(2) {
                            @media only screen and (max-width: 1199px) {
                                padding-left: 30px;
                            }

                            @media only screen and (max-width: 991px) {
                                padding-left: 15px;
                            }
                        }
                    }
                }
            }

            .filter-col {
                min-width: 200px;

                @media only screen and (max-width: 767px) {
                    min-width: 120px;
                }

                &:nth-child(2) {
                    // padding-left: 76px;
                    padding-right:100px;
                    @media only screen and (max-width: 991px) {
                        padding-right:70px;
                    }
                    @media only screen and (max-width: 767px) {
                        padding-right:50px;
                    }
                    @media only screen and (max-width: 575px) {
                        padding-left: 12px;
                        width: 50%;
                        padding-right:15px;
                    }
                }

                &:nth-child(3) {
                    padding-left: 20px;

                    @media only screen and (max-width: 1199px) {
                        padding-left: 12px;
                    }

                    @media only screen and (max-width: 575px) {
                        width: 50%;
                    }
                }

                .open-filter {
                    margin-bottom: 30px;
                }

                .title-box {
                    margin-bottom: 10px;

                    h4 {
                        font-family: 'Barlow';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        text-transform: capitalize;
                        color: #2B2B2B;
                        padding: 0;
                    }
                }

                .duration-div {
                    display: flex;
                    width: 100%;

                    .left-dd {
                        width: 50%;
                        margin-right: 12px;

                        input {
                            border: 0.5px solid #707070;
                            border-radius: 2px;
                        }
                    }

                    .right-dd {
                        width: 50%;
                    }
                }

                .check-div {
                    &.right-ul {
                        .scrollbar {
                            overflow-y: scroll;
                            max-height: 310px;
                            margin-bottom: 25px;

                        }

                        #style-1::-webkit-scrollbar-track {
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            border-radius: 10px;
                            background: #FFFFFF;
                        }

                        #style-1::-webkit-scrollbar {
                            width: 5px;
                            background: #FFFFFF;
                            margin-right: 20px;
                        }

                        #style-1::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                            background-color: #555;
                        }
                    }

                    .force-overflow {
                        padding: 0;
                        margin: 0;

                        .checkbox {
                            list-style: none;
                            margin-bottom: 10px;

                            input {
                                //display: none;

                                &:focus{
                                    box-shadow:none;
                                }

                                &:checked {
                                    +.box {
                                        &::after {
                                            opacity: 1;
                                        }

                                        &::before {
                                            background-color: #00B156;
                                        }
                                    }
                                }
                            }

                            .box {
                                font-family: 'Barlow';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: #2B2B2B;
                                padding-left: 25px;
                                position: relative;
                                cursor: pointer;

                                span {
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #707070;
                                }

                                &:before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    vertical-align: middle;
                                    height: 16px;
                                    width: 16px;
                                    background-color: transparent;
                                    border: 0.5px solid #707070;
                                    border-radius: 2px;
                                    -webkit-appearance: none;
                                    cursor: pointer;
                                }

                                &:after {
                                    content: "";
                                    position: absolute;
                                    top: 1px;
                                    left: 5px;
                                    display: block;
                                    width: 6px;
                                    height: 11px;
                                    border: solid #000;
                                    border-width: 0 0.19rem 0.19rem 0;
                                    transform: rotate(42deg);
                                    opacity: 0;
                                    transition: .3s;
                                }

                            }
                        }
                    }

                }
            }

            .display-checkbox {
                display: flex;
                justify-content: flex-end;
                position: relative;

                .wrapcheck {
                    display: flex;
                    flex-wrap: wrap;
                    padding-right: 70px;
                }

                .checkmark {
                    display: flex;
                    align-items: center;
                    background: #FFFFFF;
                    border: 0.5px solid rgba(112, 112, 112, 0.3);
                    border-radius: 5px;
                    padding: 4px 6px;
                    margin: 6px;

                    @media only screen and (max-width: 575px) {
                        margin: 6px 0;
                    }

                    .check-text {
                        font-family: 'Barlow';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        margin: 0;
                    }

                    svg {
                        width: 12px;
                        height: 12px;
                        margin-left: 8px;
                    }
                }

                .btn-clear {
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    background: #00B156;
                    border: 0.5px solid rgba(112, 112, 112, 0.3);
                    border-radius: 5px;
                    padding: 4px 10px;
                    margin-left: 6px;
                    margin: 0;
                    color: #1F1F1F;
                    position: absolute;
                    right: 0;
                    margin-top: 6px;
                }
            }

            .bottom-filter {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media only screen and (max-width: 767px) {
                    flex-direction: column-reverse;
                }

                .both-filter {
                    display: flex;

                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        // margin-top: 10px;
                    }
                }

                .trainers {
                    padding: 15px 30px;
                    border: 0.5px solid rgba(112, 112, 112, 0.3);
                    border-radius: 2px;
                    cursor: pointer;
                    flex: 1;

                    + .trainers{
                        margin-left: -1px;
                    }

                    &:hover, 
                    &:focus{
                        background-color: transparent;
                    }

                    &.isOpen{
                        border-color: #2B2B2B;
                        .btnArrow{
                            transform: rotate(180deg);
                        }
                    }

                    @media only screen and (max-width: 991px) {
                        padding: 15px 25px;
                    }

                    .bf-title {
                        display: flex;
                        align-items: center;
                        @media only screen and (max-width: 767px) {
                            justify-content: space-between;
                        }
                        .bf-item {
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            text-transform: uppercase;
                            color: #707070;
                        }

                        svg {
                            width: 18px;
                            height: 18px;
                            display: inline-block;
                            margin-left: 12px;

                            &.user {
                                margin-left: 0;
                                margin-right: 12px;
                            }
                        }
                    }
                }

                .form-wrap {
                    position: relative;

                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        margin-left: 20px;
                    }
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        margin-left:0;
                    }

                    input {
                        padding: 15px 50px;
                        min-width: 352px;
                        font-family: 'Barlow';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #707070;

                        @media only screen and (max-width: 991px) {
                            min-width: unset;
                        }

                        &::placeholder {
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #707070;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    svg {
                        position: absolute;
                        left: 22px;
                        height: 100%;
                        width: 20px;
                        margin: 0;
                    }
                }
            }

            .wraptrainers {
                display: inline-flex;
                margin-top: 40px;
                width: 100%;
                flex-wrap: wrap;
                // max-height: 390px;
                // overflow: scroll;

                .wt-box {
                    display: flex;
                    padding: 9px 18px;
                    background: #FFFFFF;
                    border: 0.5px solid rgba(112, 112, 112, 0.3);
                    border-radius: 2px;
                    margin-right: 12px;
                    margin-bottom: 12px;

                    @media only screen and (max-width: 991px) {
                        padding: 6px 10px;
                        margin-right: 6px;
                        margin-bottom: 6px;
                    }
                }

                .wt-left {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;

                    img {
                        width: 32px;
                        height: 32px;
                        object-fit: cover;
                        border-radius: 50%;
                    }

                    span {
                        font-family: 'Barlow';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        margin-left: 8px;
                    }
                }

                .wt-right {
                    position: relative;
                    display: flex;
                    justify-content: center;

                    .box {
                        font-family: 'Barlow';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #000000;
                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding-left: 20px;
                        position: relative;
                        cursor: pointer;

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            vertical-align: middle;
                            height: 16px;
                            width: 16px;
                            background-color: transparent;
                            border: 0.5px solid #707070;
                            border-radius: 2px;
                            cursor: pointer;
                            transform: translateY(-50%);
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: 43%;
                            left: 1px;
                            display: block;
                            width: 6px;
                            height: 11px;
                            border: solid #000;
                            border-width: 0 0.19rem 0.19rem 0;
                            transform: rotate(42deg) translateY(-50%);
                            opacity: 0;
                            transition: .3s;
                        }
                    }

                    input {
                        //display: none;

                        &:checked {
                            +.box {
                                &:after {
                                    opacity: 1;
                                }

                                &:before {
                                    background-color: #00B156;
                                }
                            }
                        }
                    }
                }
            }

            // scroll new add
            .scrollbar-new{
                overflow-y: scroll;
                max-height: 310px;
                margin-bottom: 25px;
                margin-top: 25px;
            }

            #style-3::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background: #FFFFFF;
            }

            #style-3::-webkit-scrollbar {
                width: 5px;
                background: #FFFFFF;
                margin-right: 20px;
            }

            #style-3::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: #555;
            }
            


            

        }
    }

}
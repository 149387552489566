.home-page #explore-menu-btn {
  font-family: 'League Gothic', sans-serif !important;
  font-size: 22px;
  color : #ffffff;
  border-radius: 0;
  min-height: 50px;
  --bs-btn-padding-y: 0.5rem;

  @media (max-width:1170) {
    font-size: 16px;
  }
}

.home-page #email, 
.home-page #zip_code {
  min-height: 50px;
  border-radius: 0;
}

.home-page #email::placeholder,
.home-page #zip_code::placeholder {
  font-weight: 600;
  font-size: 14px;
}
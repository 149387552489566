$primary-300 : #2B2B2B;
$primary-200 : #1F1F1F;

.my-account-nav {
    .dropdown {
        button {
            background-color: transparent;

        }
    }
    .bg-dark{
        background-color: $primary-200 !important;
    }
    .btn {
        &.show {
            color: white !important;
            background-color: $primary-300 !important;
            border-color: $primary-300 !important;
        }
    
        &:hover {
            color: white !important;
            background-color: $primary-300 !important;
            border-color: $primary-300 !important;
        }
    }
}

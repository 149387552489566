.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

.fs-12px {
  font-size: 12px;
}

.fs-13px {
  font-size: 13px;
}

.fs-16px {
  font-size: 16px;
}

.fs-17px {
  font-size: 17px;
}

.fs-18px {
  font-size: 18px;
}

.fs-20px {
  font-size: 20px;
}

.fs-22px {
  font-size: 22px;
}

.fs-23px {
  font-size: 23px;
}

.fs-26px {
  font-size: 26px;
}
.fs-28px {
  font-size: 28px;
}

.fs-35px {
  font-size: 35px;
}

.fs-41px {
  font-size: 41px;
}

.fs-45px {
  font-size: 45px;
}

.fs-52px{
  font-size: 52px;
}

.lh-50px {
  line-height: 50px;
}

.font-barlow {
  font-family: 'Barlow', sans-serif;
}

.font-league-gothic {
  font-family: 'League Gothic', sans-serif;
}

.font-glegoo {
  font-family: Glegoo, sans-serif;  
}


.text{
  &-large{
    font-size: 32px;

    @include media-breakpoint-up(lg){
      font-size: 42px;
    }
    @include media-breakpoint-up(xl){
      font-size: 52px;
    }
  }
  &-bannerlarge{
    font-size: 39px !important;

    @include media-breakpoint-up(lg){
      font-size: 42px;
    }
    @include media-breakpoint-up(xl){
      font-size: 52px;
    }
  }
}

.text{
  &-8xl{
    font-size: 42px;

    @include media-breakpoint-up(lg){
      font-size: 62px;
    }
    @include media-breakpoint-up(xl){
      font-size: 82px;
    }
  }
}

.text{
  &-xlarge{
    font-size: 50px;

    @include media-breakpoint-up(lg){
      font-size: 60px;
    }
    @include media-breakpoint-up(xl){
      font-size: 110px;
      line-height: 1.1;
    }
  }
}

.text{
  &-primary{
    &-100{
      color: $primary-100;
    }
    &-200{
      color: $primary-200;
    }
    &-300{
      color: $primary-300;
    }
  }
}
.autoshipContainer{
    // .calender-box {
    //     max-width: 700px;
    //     margin: auto;
    // }
    .btn-both-wrap{
        gap: 15px;
        flex-wrap: wrap;
    }
    .card {
        // padding:0 !important;
        @media screen and (max-width: 470px) {
            .detail-card span {
              font-size: 11px;
              padding-right: 0px !important;
            }
            .fs-3.my-3.text-center {
                font-size: 19px !important;
            }
            .savecardor .col-5 {
                width: 35%;
            }
        }
    }
    
    .btn-col{
        @media (max-width:575px) {
            flex: unset !important;
        }
    }
    .autoshipPage{
        margin-top: 50px;
        position: relative;
        @media (max-width:575px) {
            margin-top: 30px;
        }
        .product-faqs, .accordion {
            .accordion-button{
            white-space: normal;
            }
        }
        .settingclose {
            position: absolute;
            right: 0;
            top: -30px;
            svg {
                font-size: 28px;
            }
        }
        .autoshipPageHeader{
            font-size: 3.5rem;
            line-height: 1.3;
            font-family: 'League Gothic';
            text-transform: uppercase;
            @media (max-width:575px) {
                font-size: 2.5rem;
            }
        }
        .autoshipText{
            color: #666666;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 1.3;
            font-family: 'Barlow';
        }
        
        @media (max-width:1024px) {
            font-size: 2rem;
            line-height: 1.2;
        }
        @media (max-width:767px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .product-faqs{
        // max-width: 700px;
        // margin: auto;
        .accordion-body{
            background-color: #D9D9D9 !important;
            font-family: Glegoo;
            font-size: 16px;
            font-weight: 400;
            line-height: 28.69px;
            text-align: center;
        }
        .accordion-collapse{
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            position: absolute;
            z-index: 9;
            
        }
    }
    .accordion-collapse{
        .btn-group{
            .col-6{
                text-align: end !important;
                @media (max-width:575px) {
                    text-align: center !important;
                    .bg-success.btn.btn-success {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
    .card{
        // padding: 1px;
        .accordion-button{
            background-color: #2B2B2B;
            color: #fff;
            &:after{
                --bs-accordion-btn-icon : url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e"); 
            }
        }
    }

    .react-datepicker {
        .react-datepicker__month-container {
            width: 50%;
            @media (max-width: 767px) {
                float: none;
                width: unset;
            }
        }
        .react-datepicker__current-month{
            font-size: 22px;
        }
        .react-datepicker__day,.react-datepicker__day-name{
            width: 100%;
            height: 100%;
            // max-width: 70px;
            max-width: 12%;
            max-height: 70px;
            min-height: 70px;
            padding-top: 17px;
            font-size: 15px;
            @media screen and (max-width: 1200px) {
                // max-width: 50px;
                max-height: 50px;
                min-height: 50px;
                padding-top: 7px;
            }
            @media screen and (max-width: 991px) {
                // max-width: 40px;
                padding-top: 7px;
                font-size: 13px;
            }
            @media screen and (max-width: 768px) {
                // max-width: 60px;
                max-height: 50px;
                min-height: 50px;
                padding-top: 5px;
            }
            @media screen and (max-width: 500px) {
                // max-width: 40px;
                max-height: 42px;
                min-height: 42px;
                padding-top: 2px;
            }
            @media screen and (max-width: 360px) {
                // max-width: 35px;
            }
        }
        .react-datepicker__day--today {
            color: white;
            background-color: #2579BA;
            border-radius: 0.3rem;
        }
        .react-datepicker__day--today.react-datepicker__day--disabled {
            color: white;
            background-color: #5e9dcd;
            border-radius: 0.3rem;
        }
        .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled) {
            color: white;
            background-color: blue;
            border-radius: 0.3rem;
        }
    }

    .btn-group{
        a{
            display: block;
            width: 100%;
            button{
                width: 99%;
                @media screen and (max-width: 420px) {
                    font-size: 10px;
                }
            }
        }
    }
    .calenderwrapper{
        border: 1px solid #a9a9a9;
        border-radius: 3px;
        padding: 1px 2px;
        width: 100%;
    }
    .react-datepicker{
        border: none;
        width: 100%;
    }

    .react-datepicker__day--outside-month {
        height: unset !important;
        min-height: unset !important;
        background-color: transparent !important;
    }
    .react-datepicker__day {
        height: 30px;
    }

    .calenderMobileFooter{
        // width: 241.3px;
        width: 100%;
        background-color: #f0f0f0;
        border-radius: 0px 0px 3px 3px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .calenderDeskFooter{
        // width: 481.6px;
        width: 100%;
        background-color: #f0f0f0;
        border-radius: 0px 0px 3px 3px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .show-product-detail-btn svg {
        font-size: 16px;
    }
    .form-check-input {
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%0, 0, 0, 0.100%29%27/%3e%3c/svg%3e') !important;   
        border-color: #000000 !important;
    }
    .paused-bg-f98f50 {
        background-color: #f98f50 !important;
        border: 2px solid #f98f50 !important;
    }
    .resume-bg-00b156 {
        background-color: #00b156 !important;
        border: 2px solid #00b156 !important;
    }
    .auto-deliverydays {
        background-color: #00b156;
        border-radius: 0.3rem;
        position: relative;
    }
      
    .auto-deliverydays:hover:after {
        position: absolute;
        bottom: 100%;
        padding: 0.5rem;
        background-color: #333;
        color: #fff;
        border-radius: 0.25rem;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
    }

    .react-datepicker__day--outside-month {
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day.editable-deliverydays.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day.available-deliverydays.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day.available-add-deliverydays.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day.paused-deliverydays.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day.pickformyself-autoship.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day.pickforme-autoship.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day.order-planner-orders.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day.completed-autoship.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day.processing-autoship.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day--disabled.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }
    .react-datepicker__day--weekend.react-datepicker__day--outside-month{
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;
        .pseudo-element{
            background-color: transparent !important;
            background-image: none !important;
            border: none !important;
        }
    }

    .pause-available-deliverydays {
        background-color: #ccc ;
        border-radius: 0.3rem;
        position: relative;
        cursor: default;
        color: black;
    }

    .available-deliverydays {
        background-color: #ccc ;
        border-radius: 0.3rem;
        position: relative;
        cursor: default;
        color: black;
    }

    .editable-deliverydays {
        background-color: #FFE603 !important;
        border-radius: 0.3rem;
        position: relative;
    }

    .pickformyself-autoship {
        background-image: url(../../../assets/images/person-icon.svg) !important;
        background-size: 26px;
        background-position: center bottom 0px;
        background-repeat: no-repeat;
        background-color: #00b156;
        border-radius: 0.3rem;
        position: relative;
        @media screen and (max-width: 1200px) {
            background-size: 18px;
        }
        @media screen and (max-width: 500px) {
            background-size: 15px;
        }
    }
    .available-add-deliverydays{
        background-color: #ccc ;
        cursor: pointer;
        background-image: url(../../../assets/images/plus-round-new.svg) !important;
        background-size: 22px;
        background-position: center bottom 0px;
        background-repeat: no-repeat;
        border: 2px solid #00b156 !important;
        border-radius: 0.3rem;
        position: relative;
        @media screen and (max-width: 1200px) {
            background-size: 18px;
        }
        @media screen and (max-width: 500px) {
            background-size: 15px;
        }
    }

    .paused-deliverydays{
        background-color: #ccc ;
        cursor: pointer;
        background-image: url(../../../assets/images/plus-round-yellow.svg) !important;
        background-size: 22px;
        background-position: center bottom 0px;
        background-repeat: no-repeat;
        border: 2px solid #f98f50 !important;
        border-radius: 0.3rem;
        position: relative;
        @media screen and (max-width: 1200px) {
            background-size: 18px;
        }
        @media screen and (max-width: 500px) {
            background-size: 15px;
        }
    }

    .pickforme-autoship {
        background-image: url(../../../assets/images/chefs-choice.svg) !important;
        background-size: 26px;
        background-position: center bottom 2px;
        background-repeat: no-repeat;
        background-color: #00b156;
        border-radius: 0.3rem;
        position: relative;
        @media screen and (max-width: 1200px) {
            background-size: 20px;
        }
        @media screen and (max-width: 500px) {
            background-size: 15px;
        }
    }

    .order-planner-orders {
        background-size: 26px;
        background-position: center bottom 2px;
        background-repeat: no-repeat;
        background-color: #00b156;
        border-radius: 0.3rem;
        position: relative;
        @media screen and (max-width: 1200px) {
            background-size: 20px;
        }
        @media screen and (max-width: 500px) {
            background-size: 18px;
        }
    }

    .completed-autoship {
        background-image: url(../../../assets/images/circleCheck.svg) !important;
        background-size: 22px;
        background-position: center bottom 2px;
        background-repeat: no-repeat;
        background-color: #00b156;
        border-radius: 0.3rem;
        position: relative;
        cursor: default;
        @media screen and (max-width: 1200px) {
            background-size: 20px;
        }
        @media screen and (max-width: 500px) {
            background-size: 18px;
        }
    }

    .processing-autoship {
        background-image: url(../../../assets/images/delivery-truck-icon.svg) !important;
        background-size: 24px;
        background-position: center bottom 2px;
        background-repeat: no-repeat;
        background-color: #00b156;
        border-radius: 0.3rem;
        position: relative;
        cursor: default;
        @media screen and (max-width: 1200px) {
            background-size: 20px;
        }
        @media screen and (max-width: 500px) {
            background-size: 18px;
        }
    }

    .autoshipselect{
        // background-color: #d9d9d9;
        border-radius: 4px 0px 0px 0px;
        border: 1px 0px 0px 0px;
        // padding: 30px 0 !important;
        .autoshipPage{
            max-width: 78%;
            margin: auto;
            position: relative;
        }
        .editingtext{
            font-family: "Barlow";
            font-size: 20px;
            font-weight: 400;
            line-height: 28.8px;
            text-align: left;
            @media screen and (max-width:421px) {
                font-size: 16px;
            }
            .editedDate{
                font-family: "Barlow";
                font-size: 20px;
                font-weight: 700;
                line-height: 28.8px;
                text-align: left;
                @media screen and (max-width:421px) {
                    font-size: 16px;
                }
            }
        }
        .headerText{
            font-family: "Barlow";
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
        }
        .autoshipselect-row {
            // max-width: 80%;
            margin: auto !important;
            text-align: center;
            @media screen and (max-width: 768px) {
                gap: 10px;
            }
        }
        .autoshipselect-col{
            background-color: #e8e8e8 !important;
            padding: 25px;
            background: #fff;
            display: flex;
            flex-direction: column;
            gap: 15px;
            height: 100%;
            @media screen and (max-width: 768px) {
                padding: 20px;
                gap: 10px;
                height: auto;
            }
            img{
                width: 90px;
                height: 90px;
                object-fit: contain;
                @media screen and (max-width: 768px) {
                    width: 65px;
                    height: 65px;
                    object-fit: contain;
                }
            }
            .for-me-text{
                min-height: 50px;
            }
            .for-myself-text{
                min-height: 30px;
            }
        }
        .both-part{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .col{
                flex: unset !important;
            }
            .btn-both-part {
                display: flex;
                margin-top: 10px;
                padding: 0;
                justify-content: end;
                &.right{
                    justify-content: start;
                }
                &.center{
                    justify-content: center;
                }
                @media screen and (max-width: 768px) {
                    .btn,.btn-sm {
                        padding: 9px;
                        font-size: 12px;
                        margin: 0 2px !important;
                    }
                }
            }
        }
    }
    .selectcrossicon{
        position: absolute;
        right: -25px;
        top: 0;
        font-size: x-large;
    }
    .customerImageIcon{
        width: 100px !important;
        height: 100px !important;
        object-fit: contain !important;
        border-radius: 4rem !important;
    }
}
.lable-update{
    font-size: 40px !important;
}

.option-col {
    max-width: 70%;
    margin: auto;
    margin-bottom: 15px;
    .row {
        margin: 0;
        padding: 15px 10px;
        row-gap: 9px;
        @media (max-width:992px) {
            flex-wrap: wrap;
            gap: 10px;
            padding: 15px 0;
            justify-content: space-between;
        }
        .col {
            width: 50%;
            padding: 0;
            padding-right: 3px;
            @media (max-width:991px) {
                width: auto;
                flex:unset;
            }
            // flex: unset;
            .box-part {
                padding: 0;
                width: 20px;
                height: 20px;
                margin-right: 3px;
                border-radius: 4px;
                &.plus-gray-box{
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 13px;
                        height: 13px;
                        background-image: url(../../../assets/images/plus-round-new.svg) !important;
                        background-size: 13px;
                        background-position: center bottom 0px;
                        background-repeat: no-repeat;
                        transform: translate(-50%,-50%);
                    }
                }
                &.completed-box{
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 13px;
                        height: 13px;
                        background-image: url(../../../assets/images/circleCheck.svg) !important;
                        background-size: 13px;
                        background-position: center bottom 0px;
                        background-repeat: no-repeat;
                        transform: translate(-50%,-50%);
                    }
                }
                &.processing-box{
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 13px;
                        height: 13px;
                        background-image: url(../../../assets/images/delivery-truck-icon.svg) !important;
                        background-size: 13px;
                        background-position: center bottom 0px;
                        background-repeat: no-repeat;
                        transform: translate(-50%,-50%);
                    }
                }
            }

            .blue-boxes{
                background-color: #2579BA;
            }

            .green-boxes{
                background-color: #00B156;
            }

            .yellow-boxes{
                background-color: #FFE603;
            }

            .gray-boxes{
                background-color: #ccc;
            }

            .orange-boxes{
                background-color: #f98f50;
            }
        }
    }
    @media (max-width:1200px) {
        max-width: 90%;
    }
}

.editing-wrap {
    max-width: 444px;
    margin: 15px auto;
    padding: 0 15px;
    .inner-wrap {
        padding: 12px  16px;
        background: #D9D9D9;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text-editing {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
            span{
                font-weight: 700;
            }
        }
    }
}

.css-t3ipsp-control{
    border-color: #00b156 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(27, 28, 30, 0.25) !important;
}
.css-tr4s17-option{
    background-color: #00b156 !important;
}
.css-d7l1ni-option{
    background-color: #010101 !important;
    color: white !important;
}


div#tooltip-auto-add-weeks\ tooltip-cal {
    z-index: 99;
}

.btn-order-group{
    gap: 10px;
}
.input-group .btn {
    position: relative;
    z-index: 2;
    font-size: 12px;
    padding: 5px;
    min-height: 38px;
}

// @media screen and (max-width: 470px) {
//     .detail-card span {
//         font-size: 13px;
//         padding-left: 0 !important;
//         padding-right: 10px !important;
//     }
   
// }
@media screen and (max-width: 505px) {
    .card-mainrow{
        justify-content: space-between;
    }
    .card-name {
        padding: 0;
        width: 20%;
    }
    .detail-card {
        padding: 0 6px !important;
        justify-content: center !important;
        width: 54%;
    }
    .detail-card span {
        font-size: 13px;
        padding-left: 0 !important;
        padding-right: 10px !important;
    }
    .accordion-body .card-body {padding: 0;}
   
}

.msgAlert{
    max-width: 500px;
}
.box-alert .show {
    display: flex;
    align-items: center;
    padding: 20px 60px;
    max-width: 900px;
    margin: auto;
    border-radius: 8px !important;
    .alert-text{
        margin-left: 10px;
        text-align: left;
    }
    .settingclose {
        position: absolute;
        top: 2px;
        right: 7px;
        opacity: 0.3;
    }
    .info-icon {
        position: absolute;
        left: 20px;
        font-size: 22px;
        top: 50%;
        transform: translateY(-50%);
      }
}
.isautoshipmenunav{
    display: none !important;
}
.isautoshipmenufilter {
    top: 51px !important;
}
.isautoshipmenucart{
    top: 51px !important;
    position: sticky ;
    z-index: 9 !important;
    .cart{
        top: 0!important;
        position: relative !important;
        height: calc(100vh - 51px) !important;
    }
}



.fade.modal.show {
    padding: 0 !important;
}
@media screen and (max-width: 575px){
    .modal-dialog{
        .autoshipContainer .autoshipPage .autoshipPageHeader{
            font-size: 30px !important;
        }
        .card {
            padding: 0 10px;
        }
    }
}
.card-body{
    .autoShip{
        max-height: 1% !important;
    }
}
.autoshipBanner{
    padding-top: 10px;
    margin-bottom: 70px;
}

.maincolumn {
    text-align: center;
    display: flex;
    column-gap: 0;
}

.pointsmultibanner {
    background-color: #953092;
    color: white;
}

.pointsmultiImage {
    background-image: url("https://eatmightymeals.com/wp-content/uploads/2024/12/purple_trim_right_op.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    margin-left: -2px;
    width: 30px;
}
.pointsactivatetxt{
    font-size: 26px;
    @media screen and (max-width: 1225px){
        font-size: 24px;
    }
    @media screen and (max-width: 615px){
        font-size: 22px;
    }
    @media screen and (max-width: 535px){
        font-size: 18px;
    }
    @media screen and (max-width: 452px){
        font-size: 16px;
    }
}
.pointsforordertxt{
    font-size: 16px;
    @media screen and (max-width: 1225px){
        font-size: 14px;
    }
}
.modalwidth{
    .modal-dialog{
        max-width: fit-content !important;
    }
}
// .card {
//   border: 1px solid black;
//   border-radius: 0px;
//   padding: 0 20px;
//   &-header:first-child {
//     border-radius: 0px;
//   }
// }
.mobile-footer{
  .coupon-form{
    input[type="text"]{
      background-color: white;
      border-color: #ffffff;
      color: black;
      border-radius: 4px;
    }

    .form-control{
      border-radius: 5px !important;
    }
    .btn {
      margin-left: 12px;
      background-color: #ffffff;
      color: #1F1F1F;
      font-weight: 600;
      max-width: 170px;
      &.coupon-form-btn{
        border-radius: 5px !important;
      }
    }
  }

  &-cart{
    padding:20px 30px;
    border-radius: 20px 20px 0 0 ;
    
    .CloseButton{
      top: -30px;
    }
    
    .btn-close{
      &:hover{
        background-color:#00b156 !important;
      }
    }
    
    .cart-item{
      &-max{
        max-height: 305px;
      }
      &-minmax{
        max-height: 72px;
      }
      hr{
        display: none;
      }
      .row{
        margin: 0;
      }
      .col{
        padding: 0;
      }
      &-row{
        flex-direction: row-reverse;
        margin-bottom: 15px;
      }
      .col-8{
        flex: 1;
        padding: 0;
        padding-right: 20px;
      }
      .col-4{
        width: 64px;
        padding: 0;
      }
    }

  }
}
.isMobileInputForm{
  border-radius: 5px 0 0 5px !important;
  padding-right: 65px;
}
.isMobileRequiredFormbtn{
  height: 60% !important;
  border: none;
  border-radius: 0 5px 5px 0 !important;
  position: absolute;
  right: 0;
  top: 0;
}
.ismobileformbtn{
  height: 100% !important;
  border: none;
  border-radius: 0 5px 5px 0 !important;
  position: absolute;
  right: 0;
  top: 0;
}
.cartFooterMobile{
  padding: 0px 10px !important;
}
.isMobileCartBag{
  color: #ffffff !important;
}
.isMobileCartBagbtn{
  border-width: 1.5px;
}
.coupontextmob{
  display: flex;
  align-items: center;
  border-radius: 5px 0 0 5px !important;
}
.coupondltbtnmob{
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: -1px;
  border-radius: 0 5px 5px 0 !important;
}
/* Height of quantity container */
/* Quantity Field */
.plus-minus-input {
  input {
    width: 50px !important;
    font-size: 15px;
    margin: 0px 10px;
    height: 36px;
    border-radius: 0px;
  }

  /* Plus Minus Selectors */
   button {
    font-size: 22px;
    padding: 0;
    width: 32px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border-radius: 0;
  }
  .form-control{
    border-radius: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-check-input:checked {
    background-color: #00b156 !important;
    border-color: #00B156 !important;
}

.form-label:after {
    content: none !important;
}

.date-pickerForm {
    width: 10px !important;
    height: 5px !important;
    border: 0px solid gray !important;
}

.react-date-picker__inputGroup__input {
    width: 20px !important;
    height: 20px !important;
}

.react-date-picker__wrapper {
    border: 0px solid gray !important;
}

.progress {
    border-radius: 10px !important;
}

.progress-bar {
    background-color: #00a72c !important;
    overflow: visible !important;
    &.bg-danger{
        margin-left: 2% !important;
    }
}
.checkout-page .earn-pts-alert {
  border-width: 0px;
  box-shadow: 6px 6px #a7a9ac;
}

.checkout-page .coupon-entry {
  border-radius: 0px;
}

.checkout-page .coupon-entry .card-header,
.checkout-page .apply-mighty-points {
  border: 2px solid black;
  box-shadow: 6px 6px black;
}

.checkout-page{
  .returning-custmoer,
  .coupon-entry,
  .save-payment,
  .shipping-details{
    .form{
      &-switch{
        font-size: 20px !important;
      }
      &-check{
        &-input{
         background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%0, 0, 0, 0.100%29%27/%3e%3c/svg%3e') !important;   
         border-color: #000000 !important;
        }
      }
    }
  }
}

.authsavedcards{
  .row{
    height: 100px !important;
    .fontmobile{
      font-size: larger !important;
    }
  }
}

.divider-line {
  width: 100%;
  border-bottom: 1px solid black;
  line-height: 0;
  margin: 10px 0;
}

.usenewcard{
  background-color: #00b156 !important;
}

.useoldcard{
  background-color: #212529 !important;
  &:hover{
    background-color: #00b156 !important;
    color: white !important;
  }
}
.cookingCallout{
  background-color: #e8e8e8 !important;
}

.cooking-top.row {
  margin: 0;
}
.cooking-top  .row {
  margin: 0;
}
.cooking-top .d-flex img {
  max-width: 100px;
  padding: 0 !important;
}
.cooking-top .middel-part{
  text-align: center;
}
.cooking-bottom { 
  .h2, .h2 {
    margin-top: .5rem !important;
  }
}
@media (max-width:1400px) {
  .text-title.py-4.fs-26px {
    font-size: 22px;
  }
  .middel-part .fs-52px {
    font-size: 40px;
  }
}
@media (max-width:1200px) {
  .cooking-top .middel-part{
    text-align: left;
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
  .text-title.py-4.fs-26px {
    font-size: 28px;
  }
  .middel-part .fs-52px {
    font-size: 52px;
  }
}
@media (max-width:992px) {
  .cookingCallout .fs-35px {
    font-size: 26px;
    margin: 0;
  }
  .cookingCallout .col-xl-4 span, .cookingCallout .col-xl-4 h3 {
    padding: 0 !important;
  }
  .cookingCallout .col-xl-4 .fs-52px {
    font-size: 40px;
    line-height: 1;
  }
  .cooking-top .cookingCallout .d-block.mb-4.mx-auto {
    max-width: 80px;
  }
  .cookingCallout{
    padding: 0 !important;
    margin: 0;
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
}
@media (max-width:900px) {
  .cookingCallout .fs-35px {
    font-size: 19px;
    margin: 0;
  }
  .cookingCallout .col-xl-4 span, .cookingCallout .col-xl-4 h3 {
    padding: 0 !important;
  }
  .cookingCallout .col-xl-4 .fs-52px {
    font-size: 40px;
    line-height: 1;
  }
  .cooking-top .cookingCallout .d-block.mb-4.mx-auto {
    max-width: 80px;
  }
  .cookingCallout{
    padding: 0 !important;
    margin: 0;
  }
  .cooking-bottom .col-xl-4 {
    padding: 5px 0;
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
}
@media (max-width: 768px) {
  .cooking-bottom {
    .fs-26px{
      font-size: 22px;
    }
  }
  .text-title.py-4.fs-26px {
    font-size: 20px;
  }
  .middel-part .fs-52px {
    font-size: 40px;
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
}
@media screen and (max-width: 575px) and (min-width: 420px) {
  .cookingCallout .col-xl-4 .fs-52px {
    font-size: 40px;
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
}
@media (max-width:575px) {
  .cooking-top .row {
    gap: 5px;
  }
  .cookingCallout .fs-35px {
    font-size: 16px;
  }
  .cookingCallout .col-xl-4 .fs-52px {
    font-size: 30px;
  }

  .middel-part .fs-52px {
    font-size: 24px;
  }
  .cooking-top.cookingCallout.p-4.row {
    padding: 20px 10px !important;
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
}
@media (max-width: 510px) {
  .cooking-bottom {
    .fs-26px{
      font-size: 20px;
    }
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
}
@media (max-width: 460px) {
  .cooking-bottom {
    h2, .h2 {
      margin-top: 0;
      font-size: calc(1.10rem + 0.9vw);
    }
    .fs-26px{
      font-size: 14px;
    }
  }
  .d-flex.justify-content-center.align-items-center.col-xl-2.col-sm-4.col-3 {
    padding: 0;
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
}
@media (max-width: 460px) {
  .text-title.py-4.fs-26px {
    font-size: 15px;
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
}
@media (max-width: 375px) {
  .text-title.py-4.fs-26px {
    font-size: 14px;
  }
  .text-end.col-xl-4{
    text-align: left !important;
  }
}
